import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { AppNavbar } from "./navbar/appNavbar"
import { ExistingOven } from "./pages/existing-oven/existing-oven"

export const routes = {
  existingOven: "/existing-oven",
  yourDay: "/your-day",
  existingPrices: "/existing-prices",
  revenue: "/revenue",
}

export const AppRoutes: FC = React.memo(() => {
  return (
    <Routes>
      <Route path={"/"} element={<AppNavbar />}>
        <Route index element={<ExistingOven />} />
      </Route>
    </Routes>
  )
})
