import React, { FC, useEffect, useState } from "react"
import { StepperContainer } from "../../components/stepper/stepper-container"
import { SelectOvenType } from "./select-oven-type"
import { SelectOvenSize } from "./select-oven-size"
import { SelectOvenAge } from "./select-oven-age"
import { SelectYourDay1 } from "./select-your-day-1"
import { SelectPrices1 } from "./select-prices-1"
import { SelectPrices2 } from "./select-prices-2"
import { SelectYourDay2 } from "./select-your-day-2"
import { SelectYourDay3 } from "./select-your-day-3"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { useAppDispatch } from "../../app/hooks"
import {
  setDayCompleted,
  setOvenCompleted,
  setPricesCompleted,
} from "../../state/oven-inputs/oven-input-slice"
import { Revenue } from "./revenue"
import { SelectYourDay2Mobile } from "./select-your-day-2-mobile"
import { SelectOvenAgeMobile } from "./select-oven-age-mobile"

export const ExistingOven: FC = React.memo(() => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const { ovenInputs } = useSelector((state: RootState) => state.ovenInputs)
  const dispatch = useAppDispatch()
  //Check for existingOven progress
  useEffect(() => {
    if (ovenInputs.ovenType && ovenInputs.ovenSize && ovenInputs.powerSupply) {
      dispatch(setOvenCompleted(true))
    } else dispatch(setOvenCompleted(false))
  }, [
    dispatch,
    ovenInputs.ovenSize,
    ovenInputs.ovenType,
    ovenInputs.powerSupply,
  ])
  //Check for your day progress
  useEffect(() => {
    if (
      (ovenInputs.convection ?? 0) +
        (ovenInputs.combiMode ?? 0) +
        (ovenInputs.steaming ?? 0) +
        (ovenInputs.idle ?? 0) ===
        100 &&
      (ovenInputs.turbo ||
        ovenInputs.light ||
        ovenInputs.medium ||
        ovenInputs.intense ||
        ovenInputs.eco ||
        ovenInputs.ecoPlus) &&
      ovenInputs.hoursPerDay &&
      ovenInputs.weeksPerYear &&
      ovenInputs.daysPerWeek
    ) {
      dispatch(setDayCompleted(true))
    } else dispatch(setDayCompleted(false))
  }, [
    dispatch,
    ovenInputs.combiMode,
    ovenInputs.convection,
    ovenInputs.eco,
    ovenInputs.ecoPlus,
    ovenInputs.idle,
    ovenInputs.intense,
    ovenInputs.light,
    ovenInputs.medium,
    ovenInputs.steaming,
    ovenInputs.turbo,
    ovenInputs.hoursPerDay,
    ovenInputs.weeksPerYear,
    ovenInputs.daysPerWeek,
  ])
  //Check for existing prices progress
  useEffect(() => {
    if (
      (ovenInputs.gasPrice || ovenInputs.electricityPrice) &&
      ovenInputs.waterHardness &&
      ovenInputs.waterPrice
    ) {
      dispatch(setPricesCompleted(true))
    } else {
      dispatch(setPricesCompleted(false))
    }
  }, [
    dispatch,
    ovenInputs.electricityPrice,
    ovenInputs.gasPrice,
    ovenInputs.waterHardness,
    ovenInputs.waterPrice,
  ])

  return (
    <StepperContainer>
      <SelectOvenType />
      <SelectOvenSize />
      {/* <SelectOvenPowerSupply /> */}
      {isMobile ? <SelectOvenAgeMobile /> : <SelectOvenAge />}
      <SelectYourDay1 />
      {isMobile ? <SelectYourDay2Mobile /> : <SelectYourDay2 />}
      <SelectYourDay3 />
      <SelectPrices1 />
      <SelectPrices2 />
      <Revenue />
    </StepperContainer>
  )
})
