export enum OvenSize1 {
  GN61 = "6-1/1 GN",
  GN101 = "10-1/1 GN",
  GN201 = "20-1/1 GN",
  GN62 = "6-2/1 GN",
  GN102 = "10-2/1 GN",
  GN202 = "20-2/1 GN",
}

export enum OvenSize2 {
  BAKE6 = "6-400x600 EN",
  BAKE9 = "9-400x600 EN",
  BAKE16 = "16-400x600 EN",
}

export const OvenSize = {
  ...OvenSize1,
  ...OvenSize2,
}
export type OvenSize = (typeof OvenSize)[keyof typeof OvenSize]
