import { Input, InputProps, styled } from "@mui/material"
import React, { FC, useCallback } from "react"
import { Colors } from "../app/constants"

const StyledInput = styled(Input)<InputProps>(({ theme }) => ({
  backgroundColor: Colors.white,
  outline: "none",
  height: 60,
  boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
}))

type Props = {} & InputProps

export const BasicInput: FC<Props> = React.memo((props) => {
  const selectText = useCallback((event: any) => {
    event.target.select()
  }, [])

  return (
    <StyledInput
      type="number"
      onFocus={selectText}
      disableUnderline={true}
      inputMode="numeric"
      {...props}
    />
  )
})
