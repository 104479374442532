import { Typography } from "@mui/material"
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { Colors } from "../app/constants"
import { FlexRow } from "./basic-components/flex-row"
import { BasicInput } from "./basic-input"
import { OvenInputModel, PropertyPayload } from "../state/oven-inputs/types"
import { useAppDispatch } from "../app/hooks"
import { setProperty } from "../state/oven-inputs/oven-input-slice"
import "./label-input.scss"

type Props = {
  label: string
  onChange?: (text?: number) => void
  defaultValue?: number
  property?: keyof OvenInputModel
  postfix?: string
  disabled?: boolean
  controlledValue?: number
  isDecimal?: boolean
  maxValue?: number
  maxDigits?: number
  tabIndex?: number
}

export const LabelInput: FC<Props> = React.memo(
  ({
    label,
    onChange,
    defaultValue,
    property,
    postfix,
    disabled,
    controlledValue,
    isDecimal,
    maxValue,
    maxDigits,
    tabIndex,
  }) => {
    const dispatch = useAppDispatch()
    const [input, setInput] = useState<number | string | undefined>(
      defaultValue,
    )

    const onChangeEvent = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // Removes any inconvenience letters or symbols
        const text = event.target.value
          .replace(/(?!^)-/g, "") // removes any - that is not first character in string
          .replace(/,/g, ".") // replaces , with .
          .replace(/\..*/, (c) => "." + c.replace(/\./g, () => "")) // ensures there is at most 1 . in string
          .replace(/ /g, "") // replaces any spaces with nothing
        let rgx = isDecimal
          ? new RegExp("^-?[0-9]*\\.?[0-9]*$")
          : new RegExp("^[0-9]+$")
        if (rgx.test(text)) {
          
          // Handle input depending on certain props
          if (maxValue && Number(text) > maxValue) {
            return
          } else {
            setInput(text)
          }
        }
        if (!text) {
          setInput(undefined)
        }
      },
      [maxValue],
    )
    useEffect(() => {
      if (onChange && input && !isNaN(+input)) {
        onChange(+input)
      }

      if (property && input && !isNaN(+input)) {
        dispatch(
          setProperty({
            value: +input,
            property,
          } as PropertyPayload),
        )
      } else if (property) {
        dispatch(
          setProperty({
            value: undefined,
            property: property,
          } as PropertyPayload),
        )
      }
    }, [dispatch, input, onChange, property])

    return (
      <FlexRow sx={{ width: "100%", maxWidth: 400, alignItems: "center" }}>
        <Typography
          variant="h4"
          sx={{
            backgroundColor: Colors.white,
            height: 60,
            borderRight: "3px solid",
            borderColor: Colors.pageBackground,
            boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
            borderRadius: "100px 0 0 100px",
            padding: "20px",
            maxWidth: 300,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {label}
        </Typography>

        <BasicInput
          disabled={disabled}
          inputProps={{
            style: { textAlign: "end" },
            inputMode: "numeric",
            tabIndex: tabIndex,
          }}
          type="number"
          onChange={onChangeEvent}
          value={controlledValue ? controlledValue : input ?? ""}
          sx={{
            borderRadius: "0 100px 100px 0",
            width: 100,
            padding: "15px 20px 15px 10px",
            textAlign: "end",
          }}
        />
        <Typography sx={{ marginLeft: "5px" }}>{postfix}</Typography>
      </FlexRow>
    )
  },
)
