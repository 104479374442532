import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { RootState } from "../app/store"
import { localizedDynamic } from "../i18n/i18n"
import styles from "./navbar.module.css"
import { setStep } from "../state/oven-inputs/oven-input-slice"
import CheckIcon from "@mui/icons-material/Check"

interface PropsFromParent {
  from: number
  to: number
  text: string
  completed?: boolean
}

export const StyledLink: FC<PropsFromParent> = React.memo(
  ({ from, text, to, completed }) => {
    const { step } = useAppSelector((state: RootState) => state.ovenInputs)
    const dispatch = useAppDispatch()

    const navigateToStep = () => {
      dispatch(setStep(from))
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          className={
            from <= step && to >= step ? styles.active : styles.inactive
          }
          variant="h4"
          onClick={navigateToStep}
        >
          {localizedDynamic(text)}
        </Typography>
        <CheckIcon sx={{ opacity: completed ? 1 : 0 }} />
      </Box>
    )
  },
)
