import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { Colors } from "../app/constants"
import { FlexColumn } from "./basic-components/flex-column"
import { BasicDropdown, DropdownOption } from "./basic-dropdown"
import { BasicInput } from "./basic-input"
import { OvenInputModel, PropertyPayload } from "../state/oven-inputs/types"
import { setProperty } from "../state/oven-inputs/oven-input-slice"
import { useAppDispatch } from "../app/hooks"
import { useFocus } from "../hooks/use-focus"

type Props = {
  onSelect?: (value: any) => void
  options: DropdownOption[]
  onNumberChange?: (number: number) => void
  defaultDropdownValue?: any
  controlledDropdownValue?: any
  defaultNumberValue?: number
  inputProperty?: keyof OvenInputModel
  dropdownProperty?: keyof OvenInputModel
  maxValue?: number
  tabIndex?: number
  firstTarget?: boolean // Used to setfocus on element - Because Tab focus and Carousel scroll destroy each other, this was the solution
}

export const InputDropdown: FC<Props> = React.memo(
  ({
    onNumberChange,
    options,
    onSelect,
    defaultNumberValue,
    defaultDropdownValue,
    inputProperty,
    dropdownProperty,
    controlledDropdownValue,
    maxValue,
    tabIndex,
    firstTarget,
  }) => {
    const [input, setInput] = useState<number | string | undefined>(
      defaultNumberValue,
    )
    const [dropdownValue, setDropdownValue] =
      useState<any>(defaultDropdownValue)
    const dispatch = useAppDispatch()

    useEffect(() => {
      if (inputProperty && input && !isNaN(+input)) {
        dispatch(
          setProperty({
            value: +input,
            property: inputProperty,
          } as PropertyPayload),
        )
      } else if (inputProperty) {
        dispatch(
          setProperty({
            value: undefined,
            property: inputProperty,
          } as PropertyPayload),
        )
      }
    }, [dispatch, input, inputProperty])

    useEffect(() => {
      if (dropdownProperty) {
        dispatch(
          setProperty({
            value: dropdownValue,
            property: dropdownProperty,
          } as PropertyPayload),
        )
      }
    }, [dispatch, dropdownValue, dropdownProperty])

    const currencyFormatter = new Intl.NumberFormat(window.navigator.language, {
      maximumFractionDigits: 2,
    })

    const onChangeEvent = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // Removes any inconvenience letters or symbols
        let text = event.target.value
          .replace(/(?!^)-/g, "") // removes any - that is not first character in string
          .replace(/,/g, ".") // replaces , with .
          .replace(/\..*/, (c) => "." + c.replace(/\./g, () => "")) // ensures there is at most 1 . in string
          .replace(/ /g, "") // replaces any spaces with nothing
        let rgx = new RegExp("^-?[0-9]*\\.?[0-9]*$")
        if (rgx.test(text)) {
          // Handle input depending on certain props
          if (maxValue && Number(text) > maxValue) {
            return
          } else {
            setInput(text)
          }
        }
        if (!text) {
          setInput(undefined)
        }
        if (onNumberChange && !isNaN(+text)) {
          onNumberChange(+text)
        }
      },
      [onNumberChange],
    )

    const onDropdownSelect = useCallback(
      (value: any) => {
        setDropdownValue(value)

        if (onSelect) onSelect(value)
      },
      [onSelect],
    )

    // Set focus on mount with delay, to prevent Tab from ruining carousel scroll
    const [inputRef, setInputFocus] = useFocus()
    useEffect(() => {
      if (firstTarget) {
        setTimeout(() => {
          setInputFocus()
        }, 500)
      }
    }, [inputRef])
    return (
      <FlexColumn>
        <BasicInput
          inputRef={inputRef}
          onChange={onChangeEvent}
          inputProps={{
            style: { textAlign: "center" },
            inputMode: "numeric",
            tabIndex: tabIndex,
          }}
          type="number"
          value={input ?? ""}
          sx={{
            width: 100,
            height: 40,
            borderRadius: "10px 10px 0 0",
            borderBottom: "3px solid",
            borderColor: Colors.pageBackground,
          }}
        />
        <BasicDropdown
          sx={{ height: 40, borderRadius: " 0 0 10px 10px" }}
          defaultValue={defaultDropdownValue}
          options={options}
          onSelect={onDropdownSelect}
          controlledDropdownValue={controlledDropdownValue}
        />
      </FlexColumn>
    )
  },
)
