import { Box } from "@mui/material"
import React, { Children, FC, useCallback } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Colors } from "../../app/constants"
import "./stepper.scss"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import ArrowIcon from "../../assets/arrow.png"
import { setStep } from "../../state/oven-inputs/oven-input-slice"
type Props = {
  children?: React.ReactNode
}

export const StepperContainer: FC<Props> = React.memo(({ children }) => {
  const arrayChildren = Children.toArray(children)
  const dispatch = useAppDispatch()
  const { step } = useAppSelector((state: RootState) => state.ovenInputs)
  const updateCurrentSlide = useCallback(
    (index: number) => {
      if (step !== index) dispatch(setStep(index))
    },
    [dispatch, step],
  )

  const next = useCallback(
    (clickHandler: () => void, hasNext: boolean, label: string) => {
      return (
        hasNext && (
          <img src={ArrowIcon} className="next-arrrow" onClick={clickHandler} />
        )
      )
    },
    [],
  )

  const prev = useCallback(
    (clickHandler: () => void, hasPrev: boolean, label: string) => {
      return (
        hasPrev && (
          <img src={ArrowIcon} className="prev-arrrow" onClick={clickHandler} />
        )
      )
    },
    [],
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "center",
        width: "1px",
        margin: "0 30px 0 30px",
      }}
    >
      <Box
        sx={{
          height: "100%",
          maxHeight: 700,
          width: "100%",
          maxWidth: 1400,
          flex: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Carousel
          showThumbs={false}
          selectedItem={step}
          onChange={updateCurrentSlide}
          centerSlidePercentage={10}
          renderArrowNext={next}
          renderArrowPrev={prev}
          className="carousel-container"
          swipeable={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={40}
          useKeyboardArrows={true}
        >
          {arrayChildren.map((e, i) => (
            <>
              <Box
                sx={{
                  height: "calc(100% - 30px)",
                  overflowY: "auto",
                  backgroundColor: Colors.pageBackground,
                  display: "flex",
                  flexDirection: "column",
                }}
                key={i}
              >
                {step >= i ? e : null}
              </Box>
            </>
          ))}
        </Carousel>
      </Box>
    </Box>
  )
})
