import { Box, Typography } from "@mui/material"
import React, { FC, useMemo } from "react"
import { localized, localizedDynamic } from "../i18n/i18n"
import { StyledLink } from "./styledLink"
import { LanguagePicker } from "./language-picker"
import { useSelector } from "react-redux"
import { RootState } from "../app/store"
import { OvenSize } from "../enums/oven-size"
import { OvenType } from "../enums/oven-type"
import { PowerSupply } from "../enums/power-supply"
import { FlexRow } from "../components/basic-components/flex-row"
import { useAppSelector } from "../app/hooks"

const stepBoundaries = [
  { key: 3, value: "existingOven" },
  { key: 6, value: "yourDay" },
  { key: 8, value: "existingPrices" },
  { key: 9, value: "revenue" },
]

export const navItems = {}
export const NavHeader: FC = React.memo(() => {
  const {
    existingOvenCompleted,
    existingPricesCompleted,
    yourDayCompleted,
    ovenInputs,
  } = useSelector((state: RootState) => state.ovenInputs)

  const selectedOptions = useMemo(() => {
    if (ovenInputs.ovenType && ovenInputs.ovenSize)
      return `${localized("existingOven")} : \xa0${localized(
        ovenInputs.ovenType as OvenType,
      )} : \xa0${ovenInputs.ovenSize as OvenSize} : \xa0${localized(
        ovenInputs.powerSupply as PowerSupply,
      )}`
      else if (ovenInputs.ovenType){
        return `${localized("existingOven")} : \xa0${localized(
          ovenInputs.ovenType as OvenType,
        )}`
      }
    else {
      return localized("existingOven");
    }
  }, [ovenInputs])
  const { step } = useAppSelector((state: RootState) => state.ovenInputs)
  return (
    <Box
      sx={{
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 30px 0 30px",
      }}
    >
      <FlexRow gap={2} alignItems={"center"} marginTop={"2vh"}>
        <Typography variant="h1" gutterBottom>
          {localized("RoiCalculator")}
        </Typography>
      </FlexRow>

      {/* Display when large screen */}
      <FlexRow maxWidth={"1400px"} width={1} alignItems={"center"}>
        <Box
          sx={{
            "@media (max-width:800px)": {
              display: "none",
            },
            display: "flex",
            gap: 2,

            width: "100%",
            alignItems: "center",
            "&::before": {
              content: '""',
              margin: "auto" /* Make it push flex items to the center */,
            },
            "&::after": {
              content: '""',
              margin: "auto" /* Make it push flex items to the center */,
            },
          }}
        >
          <StyledLink
            completed={existingOvenCompleted}
            text={localized("existingOven")}
            from={0}
            to={2}
          />
          <Typography variant="h5">{">"}</Typography>
          <StyledLink
            completed={yourDayCompleted}
            text={localized("yourDay")}
            from={3}
            to={5}
          />
          <Typography variant="h5">{">"}</Typography>
          <StyledLink
            completed={existingPricesCompleted}
            text={localized("existingPrices")}
            from={6}
            to={7}
          />
          <Typography variant="h5">{">"}</Typography>
          <StyledLink text={localized("revenue")} from={8} to={8} />
        </Box>

        {/* Display when small screen */}
        <Box
          sx={{
            display: "none",
            "@media (max-width:800px)": {
              display: "flex",
              width: "100%",
            },
          }}
        >
          <Typography variant="h4">{`${localized("Step")} ${
            step + 1
          }: ${localizedDynamic(
            stepBoundaries.find((x) => x.key >= step)?.value,
          )}`}</Typography>
        </Box>
        <LanguagePicker />
      </FlexRow>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            padding: "10px 0 10px 0",
            maxWidth: "1400px",
            width: "100%",
          }}
          variant="h4"
        >
          {selectedOptions}
        </Typography>
      </Box>
    </Box>
  )
})
