import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Typography } from "@mui/material"
import React, { FC, useCallback } from "react"

import { Colors } from "../app/constants"
import { nextStep, setProperty } from "../state/oven-inputs/oven-input-slice"
import { PropertyPayload } from "../state/oven-inputs/types"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { RootState } from "../app/store"
import { StyledBox } from "./styled-box"
type Props = {
  title: string
  payload: PropertyPayload
  onClick?: () => void
}
export const SelectButton: FC<Props> = React.memo(
  ({ title, payload, onClick }) => {
    const dispatch = useAppDispatch()
    const propertyValue = useAppSelector(
      (state: RootState) => state.ovenInputs.ovenInputs[payload.property],
    )

    const click = useCallback(() => {
      dispatch(nextStep())
      dispatch(setProperty(payload))
      if (onClick) {
        onClick()
      }
    }, [dispatch, onClick, payload])
    return (
      <StyledBox
        sx={
          propertyValue === payload.value
            ? { backgroundColor: Colors.black, color: Colors.white }
            : null
        }
        onClick={click}
      >
        <Typography
          sx={
            propertyValue === payload.value
              ? { color: Colors.white }
              : { color: Colors.black }
          }
          variant="h4"
        >
          {title}
        </Typography>
        <ArrowForwardIcon
          sx={{ position: "absolute", right: 20, width: 35, height: 35 }}
        />
      </StyledBox>
    )
  },
)
