import { Box, Typography } from "@mui/material"
import React, { FC, useEffect, useMemo } from "react"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { InputDropdown } from "../../components/input-dropdown"
import { getCurrencyOptions } from "../../utils/enum-helper"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { PowerSupply } from "../../enums/power-supply"
import { setProperty } from "../../state/oven-inputs/oven-input-slice"
import { useAppDispatch } from "../../app/hooks"

export const SelectPrices1: FC = React.memo(() => {
  const { powerSupply, selectedCurrency, electricityPrice, gasPrice } =
    useSelector((state: RootState) => state.ovenInputs.ovenInputs)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (powerSupply === PowerSupply.Electric) {
      dispatch(setProperty({ property: "gasPrice", value: undefined }))
    } else if (powerSupply === PowerSupply.Gas) {
      dispatch(setProperty({ property: "electricityPrice", value: undefined }))
    }
  }, [dispatch, powerSupply])

  const getInputDropdown = useMemo(() => {
    if (!powerSupply) {
      return <Typography fontSize={25}>{localized("ChoosePSFirst")}</Typography>
    }
    if (powerSupply === PowerSupply.Electric) {
      return (
        <InputDropdown
          key={PowerSupply.Electric}
          options={getCurrencyOptions()}
          defaultDropdownValue={selectedCurrency}
          defaultNumberValue={electricityPrice}
          controlledDropdownValue={selectedCurrency}
          inputProperty="electricityPrice"
          dropdownProperty="selectedCurrency"
          maxValue={9999}
          tabIndex={13}
        
        />
      )
    } else {
      return (
        <InputDropdown
          key={PowerSupply.Gas}
          options={getCurrencyOptions()}
          defaultNumberValue={gasPrice}
          defaultDropdownValue={selectedCurrency}
          controlledDropdownValue={selectedCurrency}
          inputProperty="gasPrice"
          dropdownProperty="selectedCurrency"
          maxValue={9999}
          tabIndex={13}
        />
      )
    }
  }, [electricityPrice, selectedCurrency, gasPrice, powerSupply])

  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("Prices.Title1")}</Typography>
      {getInputDropdown}
    </Box>
  )
})
