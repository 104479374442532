import { Input, InputProps, styled } from "@mui/material"
import React, { FC, useCallback, useEffect } from "react"
import { useAppDispatch } from "../../app/hooks"
import { nextStep, setStep } from "../../state/oven-inputs/oven-input-slice"

export const KeyboardListener: FC<InputProps> = React.memo((props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const tabListener = (ev: KeyboardEvent) => {
      // Specific listener to each tabindex - Sadly this was the only solution atm.
      const documentAsAny = document as any // somehow document.activeElement.tabIndex works, but doesn't exist
      if (
        (ev.key === "Tab" || ev.key === "Enter") &&
        documentAsAny.activeElement &&
        (documentAsAny.activeElement.tabIndex === 0 ||
          documentAsAny.activeElement.tabIndex === 3 ||
          documentAsAny.activeElement.tabIndex === 6 ||
          documentAsAny.activeElement.tabIndex === 12 ||
          documentAsAny.activeElement.tabIndex === 13 ||
          documentAsAny.activeElement.tabIndex === 15)
      ) {
        ev.preventDefault()
        dispatch(nextStep())
      }
    }
    window.addEventListener("keydown", tabListener)

    return () => {
      window.removeEventListener("keydown", tabListener)
    }
  }, [dispatch])

  return null
})
