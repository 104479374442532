import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TimeRange } from "../../enums/time-range"
import { OvenCostModel } from "../../models/oven-cost-model"
import { OvenInputModel, OvenInputsState, PropertyPayload } from "./types"
import { PowerSupply } from "../../enums/power-supply"
import { OvenSize } from "../../enums/oven-size"
import { OvenType } from "../../enums/oven-type"

const initialState: OvenInputsState = {

  ovenInputs: {
    powerSupply: PowerSupply.Electric,

    // TODO: For test purposes
    // ovenSize: OvenSize.GN101,
    // ovenType: OvenType.Hybrid,
    // convection: 70,
    // combiMode: 10,
    // steaming: 10,
    // idle: 10,
    // hoursPerDay: 10,
    // daysPerWeek: 5,
    // weeksPerYear: 50,
    // electricityPrice: 3.39,
    // medium: 5,
    // intense: 5,
    // waterPrice: 1,
    // waterHardness: 5,
    // selectedCurrency: 0,
    // waterHardnessUnit: 0,
    // ovenAge: 10
  },
  step: 0,
  existingOvenCompleted: false,
  existingPricesCompleted: false,
  yourDayCompleted: false,
  calculateState: {
    selectedTimerange: TimeRange.PerWeek,
  },
}

export const ovenInputsSlice = createSlice({
  name: "ovenInputs",
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PropertyPayload>) => {
      state.ovenInputs[action.payload.property] = action.payload.value
    },
    nextStep: (state) => {
      state.step = ++state.step
    },
    prevStep: (state) => {
      state.step = --state.step
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    setOvenInput: (state, action: PayloadAction<OvenInputModel>) => {
      state.ovenInputs = action.payload
    },
    setOvenCompleted: (state, action: PayloadAction<boolean>) => {
      state.existingOvenCompleted = action.payload
    },
    setDayCompleted: (state, action: PayloadAction<boolean>) => {
      state.yourDayCompleted = action.payload
    },
    setPricesCompleted: (state, action: PayloadAction<boolean>) => {
      state.existingPricesCompleted = action.payload
    },
    setOvenCostModels: (
      state,
      action: PayloadAction<{
        invoqModel: OvenCostModel
        vcModel: OvenCostModel
      }>,
    ) => {
      state.calculateState.vcModel = action.payload.vcModel
      state.calculateState.invoqModel = action.payload.invoqModel
    },
    setSelectedTimerange: (state, action: PayloadAction<TimeRange>) => {
      state.calculateState.selectedTimerange = action.payload
    },
  },
})

export const {
  setProperty,
  nextStep,
  prevStep,
  setStep,
  setOvenInput,
  setDayCompleted,
  setOvenCompleted,
  setPricesCompleted,
  setOvenCostModels,
  setSelectedTimerange,
} = ovenInputsSlice.actions
export default ovenInputsSlice.reducer
