import { CarouselProps } from "react-responsive-carousel"
import { OvenSize } from "../enums/oven-size"
import { OvenType } from "../enums/oven-type"
import { PowerSupply } from "../enums/power-supply"

export interface ICarecycleProps extends CarouselProps { }

export class CarecycleProps {
  turbo?: number
  light?: number
  medium?: number
  intense?: number
  eco?: number
  ecoPlus?: number
}

export interface CarecycleComsumption {
  water: CarecycleProps
  kwh: CarecycleProps
}

export interface CarecycleConstant {
  // Describes which ovens this include
  combination: CombinationModel
  invoq: CarecycleComsumption
  visualCooking: CarecycleComsumption
}

export const CarecycleConstantValues = [
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 17,
        light: 20,
        medium: 18.8,
        intense: 18.2,
        eco: 25.7,
        ecoPlus: 26.5,
      },
      kwh: {
        turbo: 1.45,
        light: 1.1,
        medium: 1.48,
        intense: 2.02,
        eco: 1.6,
        ecoPlus: 1.06,
      },
    },
    visualCooking: {
      water: {
        turbo: 15,
        light: 13.1,
        medium: 20.1,
        intense: 24.3,
        eco: 20.1,
        ecoPlus: 24.3,
      },
      kwh: {
        turbo: 2,
        light: 2.1,
        medium: 3.88,
        intense: 4.86,
        eco: 3.88,
        ecoPlus: 4.86,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.8, 
        light: 22.6, 
        medium: 22.8,
        intense: 22.6,
        eco: 28.9,
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 1.80,
        light: 1.39,
        medium: 1.85,
        intense: 2.16,
        eco: 1.50, 
        ecoPlus: 1.37,
      },
    },
    visualCooking: {
      water: {
        turbo: 16.4,
        light: 19.8,
        medium: 34.9,
        intense: 59.7,
        eco: 34.9,
        ecoPlus: 59.7,
      },
      kwh: {
        turbo: 2.39,
        light: 4.01,
        medium: 4.5, //estimate
        intense: 5.11, 
        eco: 4.5,
        ecoPlus: 5.11,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.66,
        light: 23, // Estimate
        medium: 23, // Estimate
        intense: 23, // Estimate
        eco: 28.9, 
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 2.06, 
        light: 1.8, //Estimate
        medium: 2.1, //estimate
        intense: 2.3, //estimate
        eco: 2.5,
        ecoPlus: 1.88,
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 32, //estimate
        ecoPlus: 40, // estimate
      },
      kwh: {
        turbo: 2.7, //Estimate
        light: 4.4, //Estimate
        medium: 5, //Estimate
        intense: 5.6, //Estimate
        eco: 5, //Estimate
        ecoPlus: 5.6, //Estimate
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 18.5,//estimate + ca. 10% compared to 6.1
        light: 22,//estimate + ca. 10% compared to 6.1
        medium: 21,//estimate + ca. 10% compared to 6.1
        intense: 21.2,//estimate + ca. 10% compared to 6.1
        eco: 28.3,//estimate + ca. 10% compared to 6.1
        ecoPlus: 29,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 1.6,//estimate + ca. 10% compared to 6.1
        light: 1.2,//estimate + ca. 10% compared to 6.1
        medium: 1.65,//estimate + ca. 10% compared to 6.1
        intense: 2.22,//estimate + ca. 10% compared to 6.1
        eco: 1.76,//estimate + ca. 10% compared to 6.1
        ecoPlus: 1.16,//estimate + ca. 10% compared to 6.1
      },
    },
    visualCooking: {
      water: {
        turbo: 16.5,//estimate + ca. 10% compared to 6.1
        light: 14.2,//estimate + ca. 10% compared to 6.1
        medium: 22.1,//estimate + ca. 10% compared to 6.1
        intense: 26.7,//estimate + ca. 10% compared to 6.1
        eco: 22.1,//estimate + ca. 10% compared to 6.1
        ecoPlus: 26.7,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 2.2,//estimate + ca. 10% compared to 6.1
        light: 2.3,//estimate + ca. 10% compared to 6.1
        medium: 4.28,//estimate + ca. 10% compared to 6.1
        intense: 5.36,//estimate + ca. 10% compared to 6.1
        eco: 4.28,//estimate + ca. 10% compared to 6.1
        ecoPlus: 5.36,//estimate + ca. 10% compared to 6.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.5, //Estimate
        light: 23.46, 
        medium: 23.06, 
        intense: 23.09,
        eco: 29.8, 
        ecoPlus: 31.6, 
      },
      kwh: {
        turbo: 1.4, // Estimate
        light: 1.24, 
        medium: 1.64,
        intense: 1.8,
        eco: 1.41,
        ecoPlus: 1.19, 
      },
    },
    visualCooking: {
      water: {
        turbo: 16.4,//estimate same as 10.1
        light: 19.8,//estimate same as 10.1
        medium: 34.9,//estimate same as 10.1
        intense: 59.7,//estimate same as 10.1
        eco: 34.9,//estimate same as 10.1
        ecoPlus: 59.7,//estimate same as 10.1
      },
      kwh: {
        turbo: 2.39,//estimate same as 10.1
        light: 4.01,//estimate same as 10.1
        medium: 4.5, //estimate same as 10.1
        intense: 5.11, //estimate same as 10.1
        eco: 4.5,//estimate same as 10.1
        ecoPlus: 5.11,//estimate same as 10.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 25.99,
        light: 28.4,
        medium: 28.5, 
        intense: 28.2,
        eco: 34.3,
        ecoPlus: 35.7,
      },
      kwh: {
        turbo: 2.33, 
        light: 1.76, 
        medium: 2.25,
        intense: 2.96,
        eco: 2.47, 
        ecoPlus: 2.03,
      },
    },
    visualCooking: {
      water: {
        turbo: 18.6,//estimate + ca. 10% compared to 20.1
        light: 23.1,//estimate + ca. 10% compared to 20.1
        medium: 35,//estimate + ca. 10% compared to 20.1
        intense: 44,//estimate + ca. 10% compared to 20.1
        eco: 35, //estimate + ca. 10% compared to 20.1
        ecoPlus: 44,//estimate + ca. 10% compared to 20.1
      },
      kwh: {
        turbo: 3,//estimate + ca. 10% compared to 20.1
        light: 4.8, //estimate + ca. 10% compared to 20.1
        medium: 5.5,//estimate + ca. 10% compared to 20.1
        intense: 6.1,//estimate + ca. 10% compared to 20.1
        eco: 5.5,//estimate + ca. 10% compared to 20.1
        ecoPlus: 6.1, //estimate + ca. 10% compared to 20.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 17,
        light: 20,
        medium: 18.8,
        intense: 18.2,
        eco: 25.7,
        ecoPlus: 26.5,
      },
      kwh: {
        turbo: 1.45,
        light: 1.1,
        medium: 1.48,
        intense: 2.02,
        eco: 1.6,
        ecoPlus: 1.06,
      },
    },
    visualCooking: {
      water: {
        turbo: 15,
        light: 13.1,
        medium: 20.1,
        intense: 24.3,
        eco: 20.1,
        ecoPlus: 24.3,
      },
      kwh: {
        turbo: 2,
        light: 2.1,
        medium: 3.88,
        intense: 4.86,
        eco: 3.88,
        ecoPlus: 4.86,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.8, 
        light: 22.6, 
        medium: 22.8,
        intense: 22.6,
        eco: 28.9,
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 1.80,
        light: 1.39,
        medium: 1.85,
        intense: 2.16,
        eco: 1.50, 
        ecoPlus: 1.37,
      },
    },
    visualCooking: {
      water: {
        turbo: 16.4,
        light: 19.8,
        medium: 34.9,
        intense: 59.7,
        eco: 34.9,
        ecoPlus: 59.7,
      },
      kwh: {
        turbo: 2.39,
        light: 4.01,
        medium: 4.5, //estimate
        intense: 5.11, 
        eco: 4.5,
        ecoPlus: 5.11,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.66,
        light: 23, // Estimate
        medium: 23, // Estimate
        intense: 23, // Estimate
        eco: 28.9, 
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 2.06, 
        light: 1.8, //Estimate
        medium: 2.1, //estimate
        intense: 2.3, //estimate
        eco: 2.5,
        ecoPlus: 1.88,
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 32, //estimate
        ecoPlus: 40, // estimate
      },
      kwh: {
        turbo: 2.7, //Estimate
        light: 4.4, //Estimate
        medium: 5, //Estimate
        intense: 5.6, //Estimate
        eco: 5, //Estimate
        ecoPlus: 5.6, //Estimate
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 18.5,//estimate + ca. 10% compared to 6.1
        light: 22,//estimate + ca. 10% compared to 6.1
        medium: 21,//estimate + ca. 10% compared to 6.1
        intense: 21.2,//estimate + ca. 10% compared to 6.1
        eco: 28.3,//estimate + ca. 10% compared to 6.1
        ecoPlus: 29,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 1.6,//estimate + ca. 10% compared to 6.1
        light: 1.2,//estimate + ca. 10% compared to 6.1
        medium: 1.65,//estimate + ca. 10% compared to 6.1
        intense: 2.22,//estimate + ca. 10% compared to 6.1
        eco: 1.76,//estimate + ca. 10% compared to 6.1
        ecoPlus: 1.16,//estimate + ca. 10% compared to 6.1
      },
    },
    visualCooking: {
      water: {
        turbo: 16.5,//estimate + ca. 10% compared to 6.1
        light: 14.2,//estimate + ca. 10% compared to 6.1
        medium: 22.1,//estimate + ca. 10% compared to 6.1
        intense: 26.7,//estimate + ca. 10% compared to 6.1
        eco: 22.1,//estimate + ca. 10% compared to 6.1
        ecoPlus: 26.7,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 2.2,//estimate + ca. 10% compared to 6.1
        light: 2.3,//estimate + ca. 10% compared to 6.1
        medium: 4.28,//estimate + ca. 10% compared to 6.1
        intense: 5.36,//estimate + ca. 10% compared to 6.1
        eco: 4.28,//estimate + ca. 10% compared to 6.1
        ecoPlus: 5.36,//estimate + ca. 10% compared to 6.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 14, // TODO: Need value
        light: 17, // TODO: Need value
        medium: 24, // TODO: Need value
        intense: 25, // TODO: Need value
        eco: 30, // TODO: Need value
        ecoPlus: 35, // TODO: Need value
      },
      kwh: {
        turbo: 1, // TODO: Need value
        light: 1, // TODO: Need value
        medium: 1, // TODO: Need value
        intense: 1, // TODO: Need value
        eco: 1, // TODO: Need value
        ecoPlus: 1, // TODO: Need value
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 33, // TODO: Need value
        ecoPlus: 38, // TODO: Need value
      },
      kwh: {
        turbo: 2, // TODO: Need value
        light: 2, // TODO: Need value
        medium: 2, // TODO: Need value
        intense: 2, // TODO: Need value
        eco: 2, // TODO: Need value
        ecoPlus: 2, // TODO: Need value
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 25.99,
        light: 28.4,
        medium: 28.5, 
        intense: 28.2,
        eco: 34.3,
        ecoPlus: 35.7,
      },
      kwh: {
        turbo: 2.33, 
        light: 1.76, 
        medium: 2.25,
        intense: 2.96,
        eco: 2.47, 
        ecoPlus: 2.03,
      },
    },
    visualCooking: {
      water: {
        turbo: 18.6,//estimate + ca. 10% compared to 20.1
        light: 23.1,//estimate + ca. 10% compared to 20.1
        medium: 35,//estimate + ca. 10% compared to 20.1
        intense: 44,//estimate + ca. 10% compared to 20.1
        eco: 35, //estimate + ca. 10% compared to 20.1
        ecoPlus: 44,//estimate + ca. 10% compared to 20.1
      },
      kwh: {
        turbo: 3,//estimate + ca. 10% compared to 20.1
        light: 4.8, //estimate + ca. 10% compared to 20.1
        medium: 5.5,//estimate + ca. 10% compared to 20.1
        intense: 6.1,//estimate + ca. 10% compared to 20.1
        eco: 5.5,//estimate + ca. 10% compared to 20.1
        ecoPlus: 6.1, //estimate + ca. 10% compared to 20.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 17,
        light: 20,
        medium: 18.8,
        intense: 18.2,
        eco: 25.7,
        ecoPlus: 26.5,
      },
      kwh: {
        turbo: 1.45,
        light: 1.1,
        medium: 1.48,
        intense: 2.02,
        eco: 1.6,
        ecoPlus: 1.06,
      },
    },
    visualCooking: {
      water: {
        turbo: 15,
        light: 13.1,
        medium: 20.1,
        intense: 24.3,
        eco: 20.1,
        ecoPlus: 24.3,
      },
      kwh: {
        turbo: 2,
        light: 2.1,
        medium: 3.88,
        intense: 4.86,
        eco: 3.88,
        ecoPlus: 4.86,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.8, 
        light: 22.6, 
        medium: 22.8,
        intense: 22.6,
        eco: 28.9,
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 1.80,
        light: 1.39,
        medium: 1.85,
        intense: 2.16,
        eco: 1.50, 
        ecoPlus: 1.37,
      },
    },
    visualCooking: {
      water: {
        turbo: 16.4,
        light: 19.8,
        medium: 34.9,
        intense: 59.7,
        eco: 34.9,
        ecoPlus: 59.7,
      },
      kwh: {
        turbo: 2.39,
        light: 4.01,
        medium: 4.5, //estimate
        intense: 5.11, 
        eco: 4.5,
        ecoPlus: 5.11,
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 20.66,
        light: 23, // Estimate
        medium: 23, // Estimate
        intense: 23, // Estimate
        eco: 28.9, 
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 2.06, 
        light: 1.8, //Estimate
        medium: 2.1, //estimate
        intense: 2.3, //estimate
        eco: 2.5,
        ecoPlus: 1.88,
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 32, //estimate
        ecoPlus: 40, // estimate
      },
      kwh: {
        turbo: 2.7, //Estimate
        light: 4.4, //Estimate
        medium: 5, //Estimate
        intense: 5.6, //Estimate
        eco: 5, //Estimate
        ecoPlus: 5.6, //Estimate
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 18.5,//estimate + ca. 10% compared to 6.1
        light: 22,//estimate + ca. 10% compared to 6.1
        medium: 21,//estimate + ca. 10% compared to 6.1
        intense: 21.2,//estimate + ca. 10% compared to 6.1
        eco: 28.3,//estimate + ca. 10% compared to 6.1
        ecoPlus: 29,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 1.6,//estimate + ca. 10% compared to 6.1
        light: 1.2,//estimate + ca. 10% compared to 6.1
        medium: 1.65,//estimate + ca. 10% compared to 6.1
        intense: 2.22,//estimate + ca. 10% compared to 6.1
        eco: 1.76,//estimate + ca. 10% compared to 6.1
        ecoPlus: 1.16,//estimate + ca. 10% compared to 6.1
      },
    },
    visualCooking: {
      water: {
        turbo: 16.5,//estimate + ca. 10% compared to 6.1
        light: 14.2,//estimate + ca. 10% compared to 6.1
        medium: 22.1,//estimate + ca. 10% compared to 6.1
        intense: 26.7,//estimate + ca. 10% compared to 6.1
        eco: 22.1,//estimate + ca. 10% compared to 6.1
        ecoPlus: 26.7,//estimate + ca. 10% compared to 6.1
      },
      kwh: {
        turbo: 2.2,//estimate + ca. 10% compared to 6.1
        light: 2.3,//estimate + ca. 10% compared to 6.1
        medium: 4.28,//estimate + ca. 10% compared to 6.1
        intense: 5.36,//estimate + ca. 10% compared to 6.1
        eco: 4.28,//estimate + ca. 10% compared to 6.1
        ecoPlus: 5.36,//estimate + ca. 10% compared to 6.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 14, // TODO: Need value
        light: 17, // TODO: Need value
        medium: 24, // TODO: Need value
        intense: 25, // TODO: Need value
        eco: 30, // TODO: Need value
        ecoPlus: 35, // TODO: Need value
      },
      kwh: {
        turbo: 1, // TODO: Need value
        light: 1, // TODO: Need value
        medium: 1, // TODO: Need value
        intense: 1, // TODO: Need value
        eco: 1, // TODO: Need value
        ecoPlus: 1, // TODO: Need value
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 33, // TODO: Need value
        ecoPlus: 38, // TODO: Need value
      },
      kwh: {
        turbo: 2, // TODO: Need value
        light: 2, // TODO: Need value
        medium: 2, // TODO: Need value
        intense: 2, // TODO: Need value
        eco: 2, // TODO: Need value
        ecoPlus: 2, // TODO: Need value
      },
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      water: {
        turbo: 25.99,
        light: 28.4,
        medium: 28.5, 
        intense: 28.2,
        eco: 34.3,
        ecoPlus: 35.7,
      },
      kwh: {
        turbo: 2.33, 
        light: 1.76, 
        medium: 2.25,
        intense: 2.96,
        eco: 2.47, 
        ecoPlus: 2.03,
      },
    },
    visualCooking: {
      water: {
        turbo: 18.6,//estimate + ca. 10% compared to 20.1
        light: 23.1,//estimate + ca. 10% compared to 20.1
        medium: 35,//estimate + ca. 10% compared to 20.1
        intense: 44,//estimate + ca. 10% compared to 20.1
        eco: 35, //estimate + ca. 10% compared to 20.1
        ecoPlus: 44,//estimate + ca. 10% compared to 20.1
      },
      kwh: {
        turbo: 3,//estimate + ca. 10% compared to 20.1
        light: 4.8, //estimate + ca. 10% compared to 20.1
        medium: 5.5,//estimate + ca. 10% compared to 20.1
        intense: 6.1,//estimate + ca. 10% compared to 20.1
        eco: 5.5,//estimate + ca. 10% compared to 20.1
        ecoPlus: 6.1, //estimate + ca. 10% compared to 20.1
      },
    },
  },
  {
    combination: {
      size: OvenSize.BAKE16,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    //use 20.1
    invoq: {
      water: {
        turbo: 20.66,
        light: 23, // Estimate
        medium: 23, // Estimate
        intense: 23, // Estimate
        eco: 28.9, 
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 2.06, 
        light: 1.8, //Estimate
        medium: 2.1, //estimate
        intense: 2.3, //estimate
        eco: 2.5,
        ecoPlus: 1.88,
      },
    },
    visualCooking: {
      water: {
        turbo: 17,
        light: 21,
        medium: 32,
        intense: 40,
        eco: 32, //estimate
        ecoPlus: 40, // estimate
      },
      kwh: {
        turbo: 2.7, //Estimate
        light: 4.4, //Estimate
        medium: 5, //Estimate
        intense: 5.6, //Estimate
        eco: 5, //Estimate
        ecoPlus: 5.6, //Estimate
      },
    },
  },
  {
    combination: {
      size: OvenSize.BAKE6,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    //use 6.1 
    invoq: {
      water: {
        turbo: 17,
        light: 20,
        medium: 18.8,
        intense: 18.2,
        eco: 25.7,
        ecoPlus: 26.5,
      },
      kwh: {
        turbo: 1.45,
        light: 1.1,
        medium: 1.48,
        intense: 2.02,
        eco: 1.6,
        ecoPlus: 1.06,
      },
    },
    visualCooking: {
      water: {
        turbo: 15,
        light: 13.1,
        medium: 20.1,
        intense: 24.3,
        eco: 20.1,
        ecoPlus: 24.3,
      },
      kwh: {
        turbo: 2,
        light: 2.1,
        medium: 3.88,
        intense: 4.86,
        eco: 3.88,
        ecoPlus: 4.86,
      },
    },
  },
  {
    combination: {
      size: OvenSize.BAKE9,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    //use 10.1
    invoq: {
      water: {
        turbo: 20.8, 
        light: 22.6, 
        medium: 22.8,
        intense: 22.6,
        eco: 28.9,
        ecoPlus: 30.3,
      },
      kwh: {
        turbo: 1.80,
        light: 1.39,
        medium: 1.85,
        intense: 2.16,
        eco: 1.50, 
        ecoPlus: 1.37,
      },
    },
    visualCooking: {
      water: {
        turbo: 16.4,
        light: 19.8,
        medium: 34.9,
        intense: 59.7,
        eco: 34.9,
        ecoPlus: 59.7,
      },
      kwh: {
        turbo: 2.39,
        light: 4.01,
        medium: 4.5, //estimate
        intense: 5.11, 
        eco: 4.5,
        ecoPlus: 5.11,
      },
    },
  },
] as CarecycleConstant[]

export interface IPowerSupplyProps extends PowerSupplyProps { }

export class PowerSupplyProps {
  convection?: number
  combiMode?: number
  steaming?: number
  idle?: number
}

// interface PowerSupplyComsumption {
//   electric: PowerSupplyProps
//   gas: PowerSupplyProps
// }

export interface PowerSupplyConstant {
  // Describes which ovens this include
  combination: CombinationModel
  invoq: PowerSupplyProps
  visualCooking: PowerSupplyProps
}

export class CombinationModel {
  public size: OvenSize | undefined
  public type: OvenType | undefined
  public powerSupply: PowerSupply | undefined
}

export const PowerSupplyConstantValues = [
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 0.83,
      combiMode: 0.62,
      steaming: 0.62,
      idle: 0,
    },
    visualCooking: {
      convection: 1,//estimate
      combiMode: 2.5,//estimate
      steaming: 6.48,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.11,
      combiMode: 0.64,
      steaming: 0.67,
      idle: 0,
    },
    visualCooking: {
      convection: 1.3, //estimate
      combiMode: 2.7, //estimate
      steaming: 6.6,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.01,
      combiMode: 0.73,
      steaming: 0.83,
      idle: 0,
    },
    visualCooking: {
      convection: 1.28,
      combiMode: 3.5, //estimate
      steaming: 8.48,
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.2,
      combiMode: 0.69,
      steaming: 0.61,
      idle: 0,
    },
    visualCooking: {
      convection: 1.53,//estimate
      combiMode: 3.7,//estimate
      steaming: 10.48,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.74,
      combiMode: 1.05,
      steaming: 0.93,
      idle: 0,
    },
    visualCooking: {
      convection: 2.1,//estimate
      combiMode:  3.8, //estimate
      steaming: 9.48,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Hybrid,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 2.0,
      combiMode: 0.99,
      steaming: 1.08,
      idle: 0,
    },
    visualCooking: {
      convection: 2.48,//estimate
      combiMode: 4.0, //estimate
      steaming: 10.48,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 0.83,
      combiMode: 0.62,
      steaming: 0.62,
      idle: 0,
    },
    visualCooking: {
      convection: 1,//estimate
      combiMode:  2.7, //estimate
      steaming: 2.7,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.11,
      combiMode: 0.64,
      steaming: 0.64,
      idle: 0,
    },
    visualCooking: {
      convection: 1.3, //estimate
      combiMode:  2.9, //estimate
      steaming: 2.9,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.01,
      combiMode: 0.73,
      steaming: 0.73,
      idle: 0,
    },
    visualCooking: {
      convection: 1.28,
      combiMode:  3.5, //estimate
      steaming: 3.5,
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.2,
      combiMode: 0.77,
      steaming: 0.77,
      idle: 0,
    },
    visualCooking: {
      convection: 1.53,//estimate
      combiMode:  3.7, //estimate
      steaming: 3.7,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.74,
      combiMode: 0.93,
      steaming: 0.93,
      idle: 0,
    },
    visualCooking: {
      convection: 2.1,//estimate
      combiMode:  3.7, //estimate
      steaming: 3.7,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Combi,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 2.0,
      combiMode: 0.99,
      steaming: 0.99,
      idle: 0,
    },
    visualCooking: {
      convection: 2.48,//estimate
      combiMode:  3.9, //estimate
      steaming: 3.9,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN61,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 0.83,
      combiMode: 0.62,
      steaming: 0.62,
      idle: 0,
    },
    visualCooking: {
      convection: 1,//estimate
      combiMode:  2.6, //estimate
      steaming: 2.6,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN62,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.11,
      combiMode: 0.64,
      steaming: 0.64,
      idle: 0,
    },
    visualCooking: {
      convection: 1.3, //estimate
      combiMode:  2.8, //estimate
      steaming: 2.8,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN101,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.01,
      combiMode: 0.73,
      steaming: 0.73,
      idle: 0,
    },
    visualCooking: {
      convection: 1.28,
      combiMode:  3.5, //estimate
      steaming: 3.5,
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN102,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.2,
      combiMode: 0.77,
      steaming: 0.77,
      idle: 0,
    },
    visualCooking: {
      convection: 1.53,//estimate
      combiMode:  3.8, //estimate
      steaming: 3.8,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN201,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.74,
      combiMode: 0.93,
      steaming: 0.93,
      idle: 0,
    },
    visualCooking: {
      convection: 2.1,//estimate
      combiMode:  3.7, //estimate
      steaming: 3.7,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.GN202,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 2.0,
      combiMode: 0.99,
      steaming: 0.99,
      idle: 0,
    },
    visualCooking: {
      convection: 2.48,//estimate
      combiMode:  4.0, //estimate
      steaming: 4.0,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.BAKE16,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.74,
      combiMode: 0.93,
      steaming: 0.93,
      idle: 0,
    },
    visualCooking: {
      convection: 2.1,//estimate
      combiMode:  3.8, //estimate
      steaming: 3.8,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.BAKE6,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 0.83,
      combiMode: 0.62,
      steaming: 0.62,
      idle: 0,
    },
    visualCooking: {
      convection: 1,//estimate
      combiMode:  2.8, //estimate
      steaming: 2.8,//estimate
      idle: 0,
    },
  },
  {
    combination: {
      size: OvenSize.BAKE9,
      type: OvenType.Bake,
      powerSupply: PowerSupply.Electric
    },
    invoq: {
      convection: 1.01,
      combiMode: 0.73,
      steaming: 0.73,
      idle: 0,
    },
    visualCooking: {
      convection: 1.28,
      combiMode:  3.5, //estimate
      steaming: 3.5,
      idle: 0,
    },
  },
] as PowerSupplyConstant[]