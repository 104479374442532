import React, { FC } from "react"
import { Outlet } from "react-router-dom"
import { Box } from "@mui/material"
import { NavHeader } from "./header"
export const AppNavbar: FC = React.memo(() => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <NavHeader />
      <Box
        sx={{
          flex: "1 1 auto",
          overflowY: "scroll",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
})
