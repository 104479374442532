import { styled, Box, BoxProps } from "@mui/material"
import { Colors } from "../app/constants"

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: Colors.white,
  flexDirection: "row",
  display: "flex",
  maxWidth: 400,
  //TODO: Might need to do something at 900px
  "@media (max-width:900px)": {},
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  height: 60,
  position: "relative",
  cursor: "pointer",
  boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",

  minHeight: "60px",
  margin: "0 30px 0 30px",
  width: "fill-available",
}))
