import { OvenModel } from "../../enums/oven-model"
import { OvenSize } from "../../enums/oven-size"
import { OvenType } from "../../enums/oven-type"
import { PowerSupply } from "../../enums/power-supply"
import { TimeRange } from "../../enums/time-range"
import {
  CarecycleConstantValues,
  CarecycleComsumption,
  CarecycleConstant,
  CarecycleProps,
  CombinationModel,
  IPowerSupplyProps,
  PowerSupplyConstant,
  PowerSupplyConstantValues,
  PowerSupplyProps,
} from "../../models/oven-constants"
import {
  CostCarecycle,
  CostPowerSupply,
  OvenCostModel,
} from "../../models/oven-cost-model"
import { OvenInputModel } from "../../state/oven-inputs/types"
import { sumObject } from "../sum-object-helper"

export const getCarecycleConstantValues = (
  combi: CombinationModel,
): CarecycleConstant | undefined => {
  return (
    combi && CarecycleConstantValues.find((x) => x.combination.size === combi.size && x.combination.type === combi.type && x.combination.powerSupply === combi.powerSupply))
}

export const getPowerSupplyConstantValues = (
  combi: CombinationModel,
): PowerSupplyConstant | undefined => {
  return PowerSupplyConstantValues.find((x) => combi && CarecycleConstantValues.find((x) => x.combination.size === combi.size && x.combination.type === combi.type && x.combination.powerSupply === combi.powerSupply))
}

export const CompareInvoqAndVC = (ovenInputs: OvenInputModel) => {
  // Get constant values depending on inputs
  const carecycle = getCarecycleConstantValues({
    powerSupply: ovenInputs.powerSupply as PowerSupply,
    size: ovenInputs.ovenSize as OvenSize,
    type: ovenInputs.ovenType as OvenType,
  } as CombinationModel)
  const powerSupply = getPowerSupplyConstantValues({
    powerSupply: ovenInputs.powerSupply as PowerSupply,
    size: ovenInputs.ovenSize as OvenSize,
    type: ovenInputs.ovenType as OvenType,
  } as CombinationModel)
  console.log(carecycle, powerSupply)
  const invoqCC = carecycle?.invoq
  const visualCC = carecycle?.visualCooking
  const invoqPS = powerSupply?.invoq
  const visualPS = powerSupply?.visualCooking

  let invoqModel = { ovenModel: OvenModel.Invoq } as OvenCostModel
  let vcModel = { ovenModel: OvenModel.VisualCooking } as OvenCostModel

  // Calculate cost foreach scenario
  if (invoqCC) {
    invoqModel.costCarecycle = CalculateCarecycle(ovenInputs, invoqCC)
  }
  if (invoqPS) {
    invoqModel.costPowerSupply = CalculatePS(ovenInputs, invoqPS)
  }
  if (visualCC) {
    vcModel.costCarecycle = CalculateCarecycle(ovenInputs, visualCC)
  }
  if (visualPS) {
    vcModel.costPowerSupply = CalculatePS(ovenInputs, visualPS)
  }
  return { invoqModel, vcModel }
}

type PowerSupplyPropsArray = Array<keyof IPowerSupplyProps>
// Props array itself!
const powerSupplyArray: PowerSupplyPropsArray = Object.keys(
  new PowerSupplyProps(),
) as PowerSupplyPropsArray

export const CalculatePS = (
  ovenInputs: OvenInputModel,
  constants: PowerSupplyProps,
) => {
  // Setup basic variables to use
  const hoursPerDay = ovenInputs.hoursPerDay ?? 0
  const daysPerWeek = ovenInputs.daysPerWeek ?? 0
  const weeksPerYear = ovenInputs.weeksPerYear ?? 0
  const psPrice =
    ovenInputs.powerSupply === PowerSupply.Electric
      ? ovenInputs.electricityPrice ?? 0
      : ovenInputs.gasPrice ?? 0


  let perDay: CostPowerSupply = {
    powerSupplyCost: {},
    timeRange: TimeRange.PerDay,
  }
  let perWeek: CostPowerSupply = {
    powerSupplyCost: {},
    timeRange: TimeRange.PerWeek,
  }
  let perYear: CostPowerSupply = {
    powerSupplyCost: {},
    timeRange: TimeRange.PerYear,
  }

  // Loop each power supply prop
  powerSupplyArray.forEach((prop) => {
    // Get prop value from Constant and inputs
    const constant = constants[prop] ?? 0
    const input = ovenInputs[prop] ?? 0

    // Calculate cost per day
    perDay.powerSupplyCost[prop] =
      (hoursPerDay * (input / 100) * psPrice * constant)

    // Calculate cost per week - Depended on cost per day
    perWeek.powerSupplyCost[prop] =
      (perDay.powerSupplyCost[prop] ?? 0) * daysPerWeek

    // Calculate cost per week - Depended on cost per week
    perYear.powerSupplyCost[prop] =
      (perWeek.powerSupplyCost[prop] ?? 0) * weeksPerYear
  })

  return [perDay, perWeek, perYear]
}

type CarecyclePropsArray = Array<keyof CarecycleProps>
// Props array itself!
const carecycleArray: CarecyclePropsArray = Object.keys(
  new CarecycleProps(),
) as CarecyclePropsArray

export const CalculateCarecycle = (
  ovenInputs: OvenInputModel,
  constants: CarecycleComsumption,
) => {
  // Setup basic variables to use
  const daysPerWeek = ovenInputs.daysPerWeek ?? 0
  const weeksPerYear = ovenInputs.weeksPerYear ?? 0
  const kwhPrice = ovenInputs.electricityPrice ?? 0
  const waterPrice = ovenInputs.waterPrice ?? 0

  let perDay: CostCarecycle = {
    energykWhCost: {},
    waterCost: {},
    timeRange: TimeRange.PerDay,
  }
  let perWeek: CostCarecycle = {
    energykWhCost: {},
    waterCost: {},
    timeRange: TimeRange.PerWeek,
  }
  let perYear: CostCarecycle = {
    energykWhCost: {},
    waterCost: {},
    timeRange: TimeRange.PerYear,
  }

  // Loop each carecycle prop
  carecycleArray.forEach((prop) => {
    // Get prop value from Constant and inputs
    let constantWater = constants.water[prop] ?? 0
    const constantkWh = constants.kwh[prop] ?? 0
    const input = ovenInputs[prop] ?? 0
    //input is in liter. Update to m3.
    constantWater /= 1000;
    // Calculate cost per week
    perWeek.energykWhCost[prop] =
      (input * constantkWh * kwhPrice)
    perWeek.waterCost[prop] =
      (input * constantWater * waterPrice)

    // Calculate cost per day - Depended on cost per week
    perDay.energykWhCost[prop] =
      (perWeek.energykWhCost[prop] ?? 0) / daysPerWeek
    perDay.waterCost[prop] = (perWeek.waterCost[prop] ?? 0) / daysPerWeek

    // Calculate cost per year - Depended on cost per week
    perYear.energykWhCost[prop] =
      (perWeek.energykWhCost[prop] ?? 0) * weeksPerYear
    perYear.waterCost[prop] = (perWeek.waterCost[prop] ?? 0) * weeksPerYear
  })

  return [perDay, perWeek, perYear]
}

export const CalculateTotalCost = (
  ovenCostModel: OvenCostModel | undefined,
  timeRange: TimeRange,
) => {
  if (!ovenCostModel) {
    return 0
  }
  let sum = sumObject(ovenCostModel, undefined, {
    key: "timeRange",
    value: timeRange,
    include: true,
  })

  return sum
}

export const CalculateCarecycleCost = (
  ovenCostModel: OvenCostModel | undefined,
  timeRange: TimeRange,
  exclude: string,
) => {
  if (!ovenCostModel) {
    return 0
  }

  let sum = sumObject(ovenCostModel.costCarecycle, exclude, {
    key: "timeRange",
    value: timeRange,
    include: true,
  })
  return sum
}

export const CalculatePowerSupplyCost = (
  ovenCostModel: OvenCostModel | undefined,
  timeRange: TimeRange,
  exclude?: string,
) => {
  if (!ovenCostModel) {
    return 0
  }

  let sum = sumObject(ovenCostModel.costPowerSupply, exclude, {
    key: "timeRange",
    value: timeRange,
    include: true,
  })
  return sum
}
