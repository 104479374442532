import { Typography } from "@mui/material"
import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Colors } from "../../app/constants"
import { RootState } from "../../app/store"
import ElectricIcon from "../../assets/electricIcon.svg"
import ElectricIcon2 from "../../assets/electricIcon2.svg"
import FireIcon from "../../assets/fireIcon.svg"
import FireIcon2 from "../../assets/fireIcon2.svg"
import WaterIcon from "../../assets/waterIcon.svg"
import WaterIcon2 from "../../assets/waterIcon2.svg"
import { FlexRow } from "../../components/basic-components/flex-row"
import { PowerSupply } from "../../enums/power-supply"
import { localized } from "../../i18n/i18n"
import {
  CalculateCarecycleCost,
  CalculatePowerSupplyCost,
  CalculateTotalCost,
} from "../../utils/calculator/roi-calculator"
import { FlexColumn } from "../basic-components/flex-column"
import { RevenueIconSavings } from "./revenue-icon-savings"
import { RevenueSavings } from "./revenue-savings"
type Props = {}
export const CalculationDisplay: FC<Props> = React.memo(({}) => {
  const { ovenInputs, calculateState } = useSelector(
    (state: RootState) => state.ovenInputs,
  )

  // Calculate total savings
  const savings = useMemo(() => {
    return Math.round(
      CalculateTotalCost(
        calculateState.vcModel,
        calculateState.selectedTimerange,
      ) -
        CalculateTotalCost(
          calculateState.invoqModel,
          calculateState.selectedTimerange,
        ),
    )
  }, [
    calculateState.invoqModel,
    calculateState.vcModel,
    calculateState.selectedTimerange,
  ])

  // Calculate Electric savings
  const savingElectric = useMemo(() => {
    let electricSavings = 0
    if (ovenInputs.powerSupply === PowerSupply.Electric) {
      electricSavings +=
        CalculatePowerSupplyCost(
          calculateState.vcModel,
          calculateState.selectedTimerange,
        ) -
        CalculatePowerSupplyCost(
          calculateState.invoqModel,
          calculateState.selectedTimerange,
        )
    }

    electricSavings +=
      CalculateCarecycleCost(
        calculateState.vcModel,
        calculateState.selectedTimerange,
        "waterCost",
      ) -
      CalculateCarecycleCost(
        calculateState.invoqModel,
        calculateState.selectedTimerange,
        "waterCost",
      )

    return Math.round(electricSavings)
  }, [
    calculateState.invoqModel,
    calculateState.vcModel,
    calculateState.selectedTimerange,
    ovenInputs,
  ])

  // Calculate Water savings
  const savingWater = useMemo(() => {
    return Math.round(
      CalculateCarecycleCost(
        calculateState.vcModel,
        calculateState.selectedTimerange,
        "energykWhCost",
      ) -
        CalculateCarecycleCost(
          calculateState.invoqModel,
          calculateState.selectedTimerange,
          "energykWhCost",
        ),
    )
  }, [
    calculateState.invoqModel,
    calculateState.vcModel,
    calculateState.selectedTimerange,
  ])

  // Calculate Gas savings
  const savingsGas = useMemo(() => {
    if (ovenInputs.powerSupply === PowerSupply.Electric) {
      return 0
    }
    return Math.round(
      CalculatePowerSupplyCost(
        calculateState.vcModel,
        calculateState.selectedTimerange,
      ) -
        CalculatePowerSupplyCost(
          calculateState.invoqModel,
          calculateState.selectedTimerange,
        ),
    )
  }, [
    calculateState.invoqModel,
    calculateState.vcModel,
    calculateState.selectedTimerange,
    ovenInputs,
  ])

  // Calculate cost reductions
  const costReduction = useMemo(() => {
    const vc = CalculateTotalCost(
      calculateState.vcModel,
      calculateState.selectedTimerange,
    )
    const invoq = CalculateTotalCost(
      calculateState.invoqModel,
      calculateState.selectedTimerange,
    )
    return invoq && vc ? Math.round(((vc - invoq ) / vc) * 100) : 0
  }, [
    calculateState.invoqModel,
    calculateState.vcModel,
    calculateState.selectedTimerange,
    ovenInputs,
  ])

  return (
    <FlexRow
      sx={{
        gap: "2px",
        "@media (max-width:1000px)": {
          flexDirection: "column",
        },
        "@media (max-width:500px)": {
          width: "calc(100% - 40px)",
        },
      }}
    >
      <FlexRow
        sx={{
          borderRadius: "40px 0 0 40px",
          backgroundColor: Colors.white,
          padding: "30px",
          gap: "3vw",
          "@media (max-width:1000px)": {
            borderRadius: "40px 40px 0 0px",
          },
          "@media (max-width:500px)": {
            padding: "15px",
            gap: "1vw",
            flexDirection: "column",
          },
        }}
      >
        <RevenueSavings savings={savings} />
        <FlexRow
          sx={{ gap: "3vw", width: "100%", justifyContent: "space-evenly" }}
        >
          <RevenueIconSavings
            totalSaving={savings}
            typeSaving={savingElectric}
            iconUrl={ElectricIcon}
            iconUrl2={ElectricIcon2}
          />
          <RevenueIconSavings
            totalSaving={savings}
            typeSaving={savingsGas}
            iconUrl={FireIcon}
            iconUrl2={FireIcon2}
          />
          <RevenueIconSavings
            totalSaving={savings}
            typeSaving={savingWater}
            iconUrl={WaterIcon}
            iconUrl2={WaterIcon2}
          />
        </FlexRow>
      </FlexRow>
      <FlexRow
        sx={{
          backgroundColor: Colors.white,
          borderRadius: "0 40px 40px 0",

          padding: "30px",
          gap: "2vw",
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width:1000px)": {
            borderRadius: "0 0px 40px 40px",
          },
          "@media (max-width:500px)": {
            padding: "15px",
            gap: "1vw",
          },
        }}
      >
        <FlexColumn>
          <Typography variant="h3">{costReduction}%</Typography>
          <Typography fontSize={12}>{localized("CostReduction")}</Typography>
        </FlexColumn>
      </FlexRow>
    </FlexRow>
  )
})
