import { Box, Typography } from "@mui/material"
import React, { FC, useMemo } from "react"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { SelectButton } from "../../components/select-button"
import { OvenSize2, OvenSize1 } from "../../enums/oven-size"
import { OvenType } from "../../enums/oven-type"

export const SelectOvenSize: FC = React.memo(() => {
  const existingType = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs.ovenType,
  )

  const getSizeButtons = useMemo(() => {
    if (existingType === OvenType.Bake) {
      return Object.values(OvenSize2).map((e) => {
        return (
          <SelectButton
            title={localizedDynamic(e)}
            payload={{ value: e, property: "ovenSize" }}
            key={e}
          />
        )
      })
    } else if (
      existingType === OvenType.Combi ||
      existingType === OvenType.Hybrid
    ) {
      return Object.values(OvenSize1).map((e) => {
        return (
          <SelectButton
            title={localizedDynamic(e)}
            payload={{ value: e, property: "ovenSize" }}
            key={e}
          />
        )
      })
    } else {
      return (
        <Typography fontSize={25}>{localized("ChooseTypeFirst")}</Typography>
      )
    }
  }, [existingType])

  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("ExistingOven.Title2")}</Typography>
      {getSizeButtons}
    </Box>
  )
})
