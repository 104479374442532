import dayjs from "dayjs"
import "dayjs/locale/da"
import "dayjs/locale/en-gb"
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import danishTranslation from "./da.json"
import englishTranslation from "./en.json"
import usaTranslation from "./us.json"
import australiaTranslation from "./au.json"
import { initReactI18next } from "react-i18next"

export const Languages = {
  da: "Dansk",
  en: "English",
  us: "English-US",
  au: "English-AU",
} as const

// Flag key list https://github.com/lipis/flag-icons/tree/main/flags/4x3
export const countryFlags = {
  da: "dk",
  en: "gb",
  us: "us",
  au: "au",
}

export type LanguageType = keyof typeof Languages

export type Translation = typeof i18nResources.en.translation

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding dayjs LOCALE at the top!
const i18nResources = {
  da: {
    translation: danishTranslation,
  },
  en: {
    translation: englishTranslation,
  },
  us: {
    translation: usaTranslation,
  },
  au: {
    translation: australiaTranslation,
  },
}

export type i18nTranslationKey = any

i18n.use(initReactI18next).use(detector)
i18n.init(
  {
    detection: { order: ["path", "navigator"] },
    fallbackLng: "en",
    resources: i18nResources,
    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    react: {
      //withRef: false,
      bindI18n: "languageChanged loaded",
      bindI18nStore: "added removed",
      nsMode: "default",
    },
  },
  (err, t) => {
    const userLangSplit = navigator.language.split("-")
    let defaultLanguage = "en"

    if (userLangSplit.length > 0) {
      const option: LanguageType =
        userLangSplit[0].toLocaleLowerCase() as LanguageType
      if (Languages[option]) {
        defaultLanguage = option
      }
    }
    if (userLangSplit.length > 1) {
      const option: LanguageType =
        userLangSplit[1].toLocaleLowerCase() as LanguageType

      if (Languages[option]) {
        defaultLanguage = option
      }
    }

    i18n.changeLanguage(defaultLanguage)
  },
)

// Might need extended locale definition https://www.andiamo.co.uk/resources/iso-language-codes/
if (navigator.language) {
  console.log(navigator.language)
  const lngLower = navigator.language.toLocaleLowerCase()
  if (lngLower === "da") {
    dayjs.locale("da")
  } else if (["en", "en-us", "en-au"].includes(lngLower)) {
    dayjs.locale("en")
  } else {
    //Fall back to UK-format. Appropriate for anyone that is neither US, or specifically supported with language (like DK)
    dayjs.locale("en-gb")
  }
}

// type-safe translation lookup
export const localized = (key: keyof Translation): string => {
  return i18n.t(key)
}

export const localizedDynamic = (
  key: keyof Translation | string | undefined,
): string => {
  if (!key) {
    return ""
  }
  return i18n.t(key)
}

export const localizedInterpolation = (
  key: keyof Translation | string,
  keysToReplace: any,
): string => {
  return i18n.t(key, keysToReplace).toString()
}

export const getLocalizedObject = (key: keyof Translation): any => {
  return i18n.t(key, { returnObjects: true })
}

export const getTranslation = (language: keyof typeof Languages) =>
  i18n.getFixedT(language)

export const getLanguage = () => {
  return i18n.language === "en-US" ? "en" : i18n.language
}

export const changeLanguage = async (language: keyof typeof Languages) => {
  await i18n.changeLanguage(language)

  dayjs.locale("en")
}

export default i18n
