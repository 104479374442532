import React, { FC } from "react"
import { PieChart, Pie, Cell } from "recharts"

type Props = {
  data: any[]
}
const COLORS = ["#6e8a99", "#d4c9bc", "#342d3a", "#8d8684"]

const renderCustomizedLabel = ({ name }: any) => {
  return name
}

export const BasicPieChart: FC<Props> = React.memo(({ data }) => {
  return (
    <PieChart width={600} height={500}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={170}
        fill="#8884d8"
        dataKey="value"
        animationDuration={0}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
            style={{ outline: "none" }}
          />
        ))}
      </Pie>
    </PieChart>
  )
})
