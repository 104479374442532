import { Typography } from "@mui/material"
import React, { FC, useLayoutEffect, useState } from "react"
import useFontFaceObserver from "use-font-face-observer"
import { Colors } from "../app/constants"
import { isNullOrUndefined, makeid } from "../utils/general-helpers"
import { FlexRow } from "./basic-components/flex-row"
import "./basic-switch.scss"
type Props = {
  selectedValue?: string
  options: { key: any; value: string }[]
  onChange: (key: any) => void
}

export const BasicSwitch: FC<Props> = React.memo(
  ({ selectedValue, options, onChange }) => {
    const [randomId, setRandomId] = useState(makeid()) // Handles multiple Basic switched, duo to getelement need to find a specific element
    const isFontListLoaded = useFontFaceObserver([
      {
        family: `Poppins`,
      },
    ])

    useLayoutEffect(() => {
      // make sure font is loaded
      if (selectedValue && randomId && isFontListLoaded) {
        // get marker element and selected element
        let marker = document.getElementById("basic-input-marker" + randomId)
        let element = document.getElementById("marker-selected" + randomId)

        const selectedWidth = element?.offsetWidth
        const selectLeft = element?.offsetLeft

        if (
          marker &&
          !isNullOrUndefined(selectedWidth) &&
          !isNullOrUndefined(selectLeft)
        ) {
          // Sets width and left postion
          marker.style.width = selectedWidth! + "px"
          marker.style.left = selectLeft! + "px"
        }
      }
    }, [selectedValue, randomId, options, isFontListLoaded])

    // If font isn't loaded yet, the width of elements can differ
    if (!isFontListLoaded) {
      return null
    }

    return (
      <FlexRow
        sx={{
          position: "relative",
          backgroundColor: Colors.pageBackground,
          borderRadius: "100px",
          overflow: "hidden",
          width: "fit-content",
        }}
      >
        <div
          className="basic-input-marker"
          id={"basic-input-marker" + randomId}
        />
        {options.map((option) => {
          const onSelect = () => {
            onChange(option.key)
          }
          return (
            <Typography
              key={option.key}
              id={
                option.key === selectedValue
                  ? "marker-selected" + randomId
                  : option.key + "marker-not-selected" + randomId
              }
              sx={{ fontSize: 14, padding: "5px 10px 5px 10px" }}
              className={`select-option  ${
                option.key !== selectedValue
                  ? "switch-not-selected"
                  : "switch-selected"
              }`}
              onClick={onSelect}
            >
              {option.value}
            </Typography>
          )
        })}
      </FlexRow>
    )
  },
)
