import { Mark } from "@mui/base/useSlider"
import { Slider, SliderProps, styled } from "@mui/material"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Colors } from "../../app/constants"
import { OvenInputModel, PropertyPayload } from "../../state/oven-inputs/types"
import { useAppDispatch } from "../../app/hooks"
import { setProperty } from "../../state/oven-inputs/oven-input-slice"

type Props = {
  marks: Mark[]
  postfix?: string
  onChange?: (value: number) => void
  defaultValue?: number
  property?: keyof OvenInputModel
}

const StyledSlicer = styled(Slider)<SliderProps>(({ theme }) => ({
  "&>*:nth-of-type(3)": { display: "none" },
  "&>*:nth-last-of-type(3)": { display: "none" },

  maxWidth: "700px",
  marginBottom: 0,
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 100, // height of thump + padding
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  color: "white",
  height: 50,
  "& .MuiSlider-thumb": {
    height: 70,
    width: 4,
    backgroundColor: Colors.black,

    borderRadius: 10,
    border: 0,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    borderRadius: 100,
    boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
  },
  "& .MuiSlider-markLabel": {
    top: -30,
  },
  "& .MuiSlider-mark": {
    height: 50,
    width: 10,
    borderLeft: "2px dashed black",
  },
}))

export const Slicer: FC<Props> = React.memo(
  ({ marks, postfix, onChange, defaultValue, property }) => {
    const dispatch = useAppDispatch()
    const [input, setInput] = useState(defaultValue)
    const valueLabel = useCallback(
      (value: number, index: number) => {
        return `${value} ${postfix}`
      },
      [postfix],
    )

    useEffect(() => {
      setInput(defaultValue)
    }, [defaultValue])

    useEffect(() => {
      if (property) {
        dispatch(
          setProperty({ value: input, property: property } as PropertyPayload),
        )
      }
    }, [dispatch, input, property])

    const onChangeEvent = (
      event: React.SyntheticEvent | Event,
      value: number | Array<number>,
    ) => {
      if (typeof value === "number") {
        setInput(value)
      }

      if (onChange && typeof value === "number") {
        onChange(value)
      }
    }

    const min = useMemo(() => {
      return Math.min(...marks.map((item) => item.value))
    }, [marks])
    const max = useMemo(() => {
      return Math.max(...marks.map((item) => item.value))
    }, [marks])
    return (
      <StyledSlicer
      
        aria-label="ios slider"
        defaultValue={defaultValue}
        marks={marks}
        min={min}
        max={max}
        value={input ?? 0}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabel}
        track={false}
        onChange={onChangeEvent}
      />
    )
  },
)
