import { Box, Typography } from "@mui/material"
import React, { FC, useMemo } from "react"
import { Colors } from "../../app/constants"
import { FlexColumn } from "../../components/basic-components/flex-column"
import { FlexRow } from "../../components/basic-components/flex-row"
import { localized } from "../../i18n/i18n"
import existingOven from "../../assets/existing-oven.png"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { mapNewOvenPic, mapOldOvenPic } from "../../utils/oven-picture-helper"

type Props = {
  title: string
  ovenDetails: string
  ovenSubDetails?: string
  inverse?: boolean
}
export const RevenueDisplayOven: FC<Props> = React.memo(
  ({ title, ovenDetails, ovenSubDetails, inverse }) => {
    const ovenInputs = useSelector(
      (state: RootState) => state.ovenInputs.ovenInputs,
    )
    const ovenImage = useMemo(() => {
      if (title === localized("existingOven")) {
        return (
          <img
            style={{ borderRadius: "20px", maxWidth: "200px", height: "100%" }}
            src={mapOldOvenPic(ovenInputs)}
            alt="OvenPhoto"
          />
        )
      } else {
        return (
          <img
            style={{
              borderRadius: "20px",
              padding: "15px",
              maxWidth: "200px",
              height: "100%",
            }}
            src={mapNewOvenPic(ovenInputs)}
            alt="OvenPhoto"
          />
        )
      }
    }, [ovenInputs, title])
    return (
      <FlexRow
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: inverse ? "row-reverse" : "row",
          gap: 5,
          paddingLeft: 5,
          paddingRight: 5,
          height: "100%",
          "@media (max-width:500px)": {
            paddingLeft: 1,
            paddingRight: 1,
          },
        }}
      >
        <FlexColumn
          sx={{
            alignItems: inverse ? "flex-start" : "flex-end",
            flex: 1,
            textAlign: inverse ? "left" : "right",
            gap: 2,
            "@media (max-width:500px)": {
              gap: 1,
            },
          }}
        >
          <Typography
            sx={{
              display: "none",
              "@media (max-width:1000px)": {
                display: "block",
              },
              "@media (max-width:800px)": {
                fontSize: "1.0rem",
              },
              "@media (max-width:600px)": {
                fontSize: "0.8rem",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.6rem",
              "@media (max-width:1000px)": {
                fontSize: "1.4rem",
              },
              "@media (max-width:800px)": {
                fontSize: "1.2rem",
              },
              "@media (max-width:600px)": {
                fontSize: "1.0rem",
              },
            }}
            style={{ whiteSpace: "pre-line" }}
          >
            {ovenDetails}
          </Typography>
          <Typography
            sx={{
              "@media (max-width:800px)": {
                fontSize: "1.0rem",
              },
              "@media (max-width:600px)": {
                fontSize: "0.8rem",
              },
            }}
          >
            {ovenSubDetails}
          </Typography>
        </FlexColumn>
        <FlexColumn
          sx={{
            "@media (max-width:1000px)": {
              display: "none",
            },
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
          }}
        >
          <Typography>{title}</Typography>
          <Box
            sx={{
              flex: 1,
              backgroundColor: Colors.white,
              height: "100%",
              width: "100%",
              margin: "20px",
              borderRadius: "20px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {ovenImage}
          </Box>
        </FlexColumn>
      </FlexRow>
    )
  },
)
