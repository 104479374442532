import { Typography, TypographyProps } from "@mui/material"
import React, { FC } from "react"

interface Props extends TypographyProps {
  value?: number
}
export const DisplayCurrency: FC<Props> = React.memo(({ value, ...props }) => {
  const currencyFormatter = new Intl.NumberFormat(window.navigator.language, {
    maximumFractionDigits: 2,
  })

  return (
    <Typography {...props} className="center-overflow">
      {value ? currencyFormatter.format(value) : value}
    </Typography>
  )
})
