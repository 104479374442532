import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material/styles"

export const Colors = {
  black: "rgba(0,0,0,0.9)",
  pageBackground: "#F4F4F5",
  pageBackground2: "#E9E9E9",
  white: "#ffffff",
  success: "#32CD32",
  alert: "#FF0000",
  warning: "#CCCC00",
  greyNotSelected: "#888888",
}

export const StepperContentStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  flex: 1,
  "&::before": {
    content: '""',
    margin: "auto" /* Make it push flex items to the center */,
  },
  "&::after": {
    content: '""',
    margin: "auto" /* Make it push flex items to the center */,
  },
  "@media (max-width:700px)": {
    gap: "15px",
  },
}
