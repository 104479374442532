import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { Colors } from "../app/constants"
import { FlexColumn } from "./basic-components/flex-column"
import { BasicInput } from "./basic-input"
import { Button, Typography } from "@mui/material"
import { setProperty } from "../state/oven-inputs/oven-input-slice"
import { OvenInputModel, PropertyPayload } from "../state/oven-inputs/types"
import { useAppDispatch } from "../app/hooks"
import { useFocus } from "../hooks/use-focus"

type Props = {
  onTextChange?: (text: string) => void
  defaultValue?: number
  label: string
  property?: keyof OvenInputModel
  maxValue?: number
  maxDigit?: number
  tabIndex?: number
  firstTarget?: boolean // Used to setfocus on element - Because Tab focus and Carousel scroll destroy each other, this was the solution
}

export const PlusMinusInput: FC<Props> = React.memo(
  ({
    onTextChange,
    defaultValue,
    label,
    property,
    maxValue,
    maxDigit,
    tabIndex,
    firstTarget,
  }) => {
    const [input, setInput] = useState<number | string | undefined>(
      defaultValue,
    )

    useEffect(() => {
      setInput(defaultValue)
    }, [defaultValue])

    const dispatch = useAppDispatch()
    useEffect(() => {
      if (property && input !== undefined) {
        dispatch(
          setProperty({ value: +input, property: property } as PropertyPayload),
        )
      } else if (property) {
        dispatch(
          setProperty({
            value: undefined,
            property: property,
          } as PropertyPayload),
        )
      }
    }, [dispatch, input, property])

    const add = useCallback(() => {
      const newValue = input ? +input + 1 : 1
      setInput(newValue)
      if (onTextChange) onTextChange(newValue?.toString())
    }, [input, onTextChange])
    const subtract = useCallback(() => {
      const newValue = input ? +input - 1 : 0
      setInput(newValue)
      if (onTextChange) onTextChange(newValue?.toString())
    }, [input, onTextChange])

    const onChangeEvent = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reg = new RegExp("^[0-9]+$")
        if (reg.test(event.target.value)) {
          let value = +event.target.value

          // Handle input depending on certain props
          if (maxValue && Number(value) > maxValue) {
            return
          } else if (maxDigit && event.target.value.length > maxDigit) {
            return
          } else {
            setInput(value)
          }

          if (onTextChange) onTextChange(value.toString())
        }
        if (!event.target.value) {
          setInput(undefined)
        }
      },
      [onTextChange],
    )

    const [inputRef, setInputFocus] = useFocus()

    // Set focus on mount with delay, to prevent Tab from ruining carousel scroll
    useEffect(() => {
      if (firstTarget) {
        setTimeout(() => {
          setInputFocus()
        }, 500)
      }
    }, [inputRef])
    return (
      <FlexColumn sx={{ alignItems: "center" }}>
        <Button
          onClick={add}
          sx={{
            width: 50,
            height: 30,
            borderRadius: "10px 10px 0 0",
            borderBottom: "3px solid",
            borderColor: Colors.pageBackground,
            backgroundColor: Colors.white,
            boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
            minWidth: 0,
            color: Colors.black,
          }}
        >
          +
        </Button>
        <BasicInput
          inputRef={inputRef}
          onChange={onChangeEvent}
          inputProps={{
            style: { textAlign: "center" },
            inputMode: "numeric",
            tabIndex: tabIndex,
          }}
          // Makes component controlled
          value={input ?? ""}
          type="number"
          sx={{
            width: 50,
            height: 40,

            borderBottom: "3px solid",
            borderColor: Colors.pageBackground,
          }}
        />
        <Button
          onClick={subtract}
          sx={{
            width: 50,
            height: 30,
            borderRadius: "0 0 10px 10px",
            borderBottom: "3px solid",
            borderColor: Colors.pageBackground,
            backgroundColor: Colors.white,
            boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
            minWidth: 0,
            color: Colors.black,
          }}
        >
          -
        </Button>
        <Typography>{label}</Typography>
      </FlexColumn>
    )
  },
)
