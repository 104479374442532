import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import ovenInputReducer from "../state/oven-inputs/oven-input-slice"

export const store = configureStore({
  reducer: {
    ovenInputs: ovenInputReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
