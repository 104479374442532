import React, { FC, useCallback } from "react"
import { BasicDropdown } from "../components/basic-dropdown"
import {
  LanguageType,
  Languages,
  changeLanguage,
  countryFlags,
  getLanguage,
} from "../i18n/i18n"
import { FlexRow } from "../components/basic-components/flex-row"

import { Box } from "@mui/system"

const languageOptions = Object.keys(Languages).map((key, idx) => ({
  // label: Languages[Object.keys(Languages)[idx] as LanguageType],
  label: (
    <Box
      sx={{ width: 30 }}
      className={`fi fi-${countryFlags[key as LanguageType]}`}
    ></Box>
  ),
  value: key,
}))
export const LanguagePicker: FC = React.memo(() => {
  const languageChanged = useCallback((value: string) => {
    changeLanguage(value as LanguageType)
  }, [])
  const defaultValue = getLanguage()

  return (
    <FlexRow sx={{ gap: "20px", maxWidth: "50px" }}>
      <BasicDropdown
        sx={{ backgroundClip: "transparent", boxShadow: "none", height: '30px' }}
        options={languageOptions}
        onSelect={languageChanged}
        defaultValue={defaultValue}
      />
    </FlexRow>
  )
})
