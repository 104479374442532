import { Box, Typography } from "@mui/material"
import React, { FC, useMemo } from "react"
import { FlexColumn } from "../basic-components/flex-column"
import "./revenue-components.scss"
import { DisplayCurrency } from "./display-currency"
type Props = {
  totalSaving?: number
  typeSaving?: number
  iconUrl: string
  iconUrl2: string
}
export const RevenueIconSavings: FC<Props> = React.memo(
  ({ totalSaving, typeSaving, iconUrl, iconUrl2 }) => {
    const percent = useMemo(() => {
      return typeSaving && totalSaving
        ? Math.round((typeSaving / totalSaving) * 100)
        : 0
    }, [totalSaving, typeSaving])

    return (
      <FlexColumn sx={{ justifyContent: "center", alignItems: "center" }}>
        <Box className="revenue-icon-container">
          <div className="percent-icon" style={{}}>
            <img src={iconUrl2} alt={iconUrl2} />
          </div>
          <div
            className="percent-icon"
            style={{
              height: percent + "%",
              transform: "rotate(180deg)",
              overflow: "hidden",
            }}
          >
            <img
              style={{ transform: "rotate(180deg)" }}
              src={iconUrl}
              alt={iconUrl}
            />
          </div>
        </Box>

        <Typography>{percent}%</Typography>
        <DisplayCurrency value={typeSaving ?? 0} sx={{width: "4em"}} />
      </FlexColumn>
    )
  },
)
