import { Box, Typography } from "@mui/material"
import React, { FC, useEffect, useMemo } from "react"
import { Colors, StepperContentStyle } from "../../app/constants"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { FlexColumn } from "../../components/basic-components/flex-column"
import { FlexRow } from "../../components/basic-components/flex-row"
import { BasicPieChart } from "../../components/charts/pie-chart"
import { LabelInput } from "../../components/label-input"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { setProperty } from "../../state/oven-inputs/oven-input-slice"
import { PropertyPayload } from "../../state/oven-inputs/types"

export const SelectYourDay1: FC = React.memo(() => {
  const dispatch = useAppDispatch()
  const ovenInputs = useAppSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )

  const total = useMemo(() => {
    let tmpTotal = 0

    if (!isNaN(Number(ovenInputs.convection))) {
      tmpTotal += Number(ovenInputs.convection)
    }
    if (!isNaN(Number(ovenInputs.combiMode))) {
      tmpTotal += Number(ovenInputs.combiMode)
    }
    if (!isNaN(Number(ovenInputs.steaming))) {
      tmpTotal += Number(ovenInputs.steaming)
    }
    if (!isNaN(Number(ovenInputs.idle))) {
      tmpTotal += Number(ovenInputs.idle)
    }
    return tmpTotal
  }, [ovenInputs])

  useEffect(() => {
    let tmpTotal = 0

    if (!isNaN(Number(ovenInputs.convection))) {
      tmpTotal += Number(ovenInputs.convection)
    }
    if (!isNaN(Number(ovenInputs.combiMode))) {
      tmpTotal += Number(ovenInputs.combiMode)
    }
    if (!isNaN(Number(ovenInputs.steaming))) {
      tmpTotal += Number(ovenInputs.steaming)
    }

    dispatch(
      setProperty({
        value: tmpTotal > 100 ? 0 : 100 - tmpTotal,
        property: "idle",
      } as PropertyPayload),
    )
  }, [dispatch, ovenInputs])

  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("YourDay.Title1")}</Typography>
      <FlexRow gap={"100px"}>
        <Box
          sx={{
            "@media (max-width:1000px)": {
              display: "none",
            },
          }}
        >
          {Number(ovenInputs.idle) !== 100 ? (
            <BasicPieChart
              data={[
                {
                  name: localized("Convection"),
                  value: Number(ovenInputs.convection),
                },
                {
                  name: localized("CombiMode"),
                  value: Number(ovenInputs.combiMode),
                },
                {
                  name: localized("Steaming"),
                  value: Number(ovenInputs.steaming),
                },
                { name: localized("Idle"), value: Number(ovenInputs.idle) },
              ]}
            />
          ) : (
            <Box sx={{ width: 600, height: 500 }}></Box>
          )}
        </Box>
        <FlexColumn gap={"30px"}>
          <LabelInput
            postfix="%"
            label={localized("Convection")}
            property="convection"
            defaultValue={ovenInputs.convection}
            maxValue={100}
            tabIndex={1}
          />
          <LabelInput
            postfix="%"
            label={localized("CombiMode")}
            property="combiMode"
            defaultValue={ovenInputs.combiMode}
            maxValue={100}
            tabIndex={2}
          />
          <LabelInput
            postfix="%"
            label={localized("Steaming")}
            property="steaming"
            defaultValue={ovenInputs.steaming}
            maxValue={100}
            tabIndex={3}
          />
          <LabelInput
            postfix="%"
            label={localized("Idle")}
            disabled={true}
            controlledValue={ovenInputs.idle}
          />
          <FlexRow
            sx={{ width: "100%", maxWidth: 400, justifyContent: "flex-end" }}
          >
            <Typography
              variant="h4"
              fontWeight={"bold"}
              sx={{
                height: 70,
                borderRight: "3px solid",
                borderColor: Colors.pageBackground,
                padding: "20px",
                maxWidth: 300,
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {localized("Total")}:
            </Typography>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              sx={{
                width: 60,
                padding: "10px",
                height: 70,
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                color: total > 100 ? "red" : "",
              }}
            >
              {total + "%"}
            </Typography>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Box>
  )
})
