import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { Slicer } from "../../components/slicer/slicer"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"

export const SelectYourDay2: FC = React.memo(() => {
  const ovenInputs = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("YourDay.Title2")}</Typography>
      <Box
        sx={{
          width: "100%",
          "@media (max-width:900px)": { padding: "10px 40px 10px 10px" },
          padding: "40px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ "@media (max-width:900px)": { fontSize: "0.8rem" } }}>
          {localized("OperationHoursPrDay")}
        </Typography>
        <Slicer
          defaultValue={ovenInputs.hoursPerDay ?? undefined}
          postfix={localized("hours")}
          marks={[
            { label: "1", value: 1 },
            { label: localized("Avg") + ": 12,4", value: 12.4 },
            { label: "24", value: 24 },
          ]}
          property="hoursPerDay"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          "@media (max-width:900px)": { padding: "10px 40px 10px 10px" },
          padding: "40px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ "@media (max-width:900px)": { fontSize: "0.8rem" } }}>
          {localized("OperationDaysPrWeek")}
        </Typography>
        <Slicer
          defaultValue={ovenInputs.daysPerWeek ?? undefined}
          postfix={localized("days")}
          marks={[
            { label: "1", value: 1 },
            { label: localized("Avg") + ": 5", value: 5 },
            { label: "7", value: 7 },
          ]}
          property="daysPerWeek"
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          "@media (max-width:900px)": { padding: "10px 40px 10px 10px" },
          padding: "40px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ "@media (max-width:900px)": { fontSize: "0.8rem" } }}>
          {localized("OperationWeeksPrYear")}
        </Typography>
        <Slicer
          defaultValue={ovenInputs.weeksPerYear ?? undefined}
          postfix={localized("weeks")}
          marks={[
            { label: "1", value: 1 },
            { label: localized("Avg") + ": 42", value: 42 },
            { label: "52", value: 52 },
          ]}
          property="weeksPerYear"
        />
      </Box>
    </Box>
  )
})
