import { ThemeProvider, createTheme } from "@mui/material"
import React, { useEffect } from "react"
import ReactDOM, { Root } from "react-dom/client"
import { useTranslation } from "react-i18next"
import { Provider, useDispatch, useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { RootState, store } from "./app/store"
import { AppRoutes } from "./appRoutes"
import "./index.css"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import { useAppDispatch } from "./app/hooks"
import { setProperty } from "./state/oven-inputs/oven-input-slice"
import { PropertyPayload } from "./state/oven-inputs/types"
import { KeyboardListener } from "./components/services/keyboard-listener"
const theme = createTheme({
  typography: {
    h1: {
      fontSize: "4.0rem",

      "@media (max-width:1000px)": {
        fontSize: "2.9rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2.4rem",
      },
      "@media (max-width:400px)": {
        fontSize: "2.0rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (max-width:1000px)": {
        fontSize: "1.2rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.0rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
    },

    fontFamily: ["Poppins"].join(","),
  },
})

const LanguageRerenderer = (props: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()

  const dispatch = useAppDispatch()

  dispatch(
    setProperty({
      value: 0,
      property: "selectedCurrency",
    } as PropertyPayload),
  )
  dispatch(
    setProperty({
      value: 0,
      property: "waterHardnessUnit",
    } as PropertyPayload),
  )

  return <div key={i18n.language}>{props.children}</div>
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <KeyboardListener />
      <ThemeProvider theme={theme}>
        <LanguageRerenderer>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </LanguageRerenderer>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)
