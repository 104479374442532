import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  SxProps,
  Theme,
} from "@mui/material"
import React, { FC, useCallback, useRef, useState } from "react"

export interface DropdownOption {
  label: any
  value: string
}

type Props = {
  onSelect: (value: string) => void
  options: DropdownOption[]
  sx?: SxProps<Theme>
  defaultValue?: any
  controlledDropdownValue?: any
}

export const BasicDropdown: FC<Props> = React.memo(
  ({ onSelect, options, sx, defaultValue, controlledDropdownValue }) => {
    const [value, setValue] = useState<string | undefined>(defaultValue)
    const handleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        setValue(event.target.value)
        onSelect(event.target.value)
      },
      [onSelect],
    )

    return (
      <Select
        variant="standard"
        disableUnderline={true}
        sx={{
          backgroundColor: "white",
          width: 100,
          height: 60,
          boxShadow: "1px 1px 1px rgb(0,0,0,0.1)",
          ...sx,
        }}
        value={controlledDropdownValue ?? value}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    )
  },
)
