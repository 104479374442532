import { Box, Typography } from "@mui/material"
import React, { FC, useCallback } from "react"
import { SelectButton } from "../../components/select-button"
import { OvenType } from "../../enums/oven-type"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { useAppDispatch } from "../../app/hooks"
import { setProperty } from "../../state/oven-inputs/oven-input-slice"

export const SelectOvenType: FC = React.memo(() => {
  const dispatch = useAppDispatch()
  const onClick = useCallback(() => {
    dispatch(setProperty({ property: "ovenSize", value: undefined }))
  }, [dispatch])

  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("ExistingOven.Title1")}</Typography>
      <SelectButton
        title={localized(OvenType.Combi)}
        payload={{ value: OvenType.Combi, property: "ovenType" }}
        onClick={onClick}
      />
      <SelectButton
        title={localized(OvenType.Bake)}
        payload={{ value: OvenType.Bake, property: "ovenType" }}
        onClick={onClick}
      />
      <SelectButton
        title={localized(OvenType.Hybrid)}
        payload={{ value: OvenType.Hybrid, property: "ovenType" }}
        onClick={onClick}
      />
    </Box>
  )
})
