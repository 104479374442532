import { OvenSize } from "../enums/oven-size"
import { OvenType } from "../enums/oven-type"
import { OvenInputModel } from "../state/oven-inputs/types"
import Hybrid10_1 from "../assets/new-oven-pics/Generic_Invoq_10-1-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Hybrid10_2 from "../assets/new-oven-pics/Generic_Invoq_10-2-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Hybrid6_1 from "../assets/new-oven-pics/Generic_Invoq_6-1-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Hybrid6_2 from "../assets/new-oven-pics/Generic_Invoq_6-2-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Hybrid20_1 from "../assets/new-oven-pics/Generic_Invoq_20-1-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Hybrid20_2 from "../assets/new-oven-pics/Generic_Invoq_20-2-GN_Cam001_HYBRID_transparent_no-shadow.png"
import Combi10_1 from "../assets/new-oven-pics/Generic_Invoq_10-1-GN_Cam001_COMBI_transparent_no-shadow.png"
import Combi10_2 from "../assets/new-oven-pics/Generic_Invoq_10-2-GN_Cam001_COMBI_transparent_no-shadow.png"
import Combi6_1 from "../assets/new-oven-pics/Generic_Invoq_6-1-GN_Cam001_COMBI_transparent_no-shadow.png"
import Combi6_2 from "../assets/new-oven-pics/Generic_Invoq_6-1-GN_Cam001_COMBI_transparent_no-shadow.png"
import Combi20_1 from "../assets/new-oven-pics/Generic_Invoq_20-1-GN_Cam001_COMBI_transparent_no-shadow.png"
import Combi20_2 from "../assets/new-oven-pics/Generic_Invoq_20-1-GN_Cam001_COMBI_transparent_no-shadow.png"
import Bake6 from "../assets/new-oven-pics/Generic_Invoq_6-1-GN_Cam001_BAKE_transparent-no-shadow.png"
import Bake9 from "../assets/new-oven-pics/Generic_Invoq_10-1-GN_Cam001_BAKE_transparent_no-shadow.png"
import Bake16 from "../assets/new-oven-pics/Generic_Invoq_20-1-GN_Cam001_BAKE_transparent_no-shadow.png"

import OldOven1_10 from "../assets/old-oven-pics/1.10_35020133_black.png"
import OldOven1_20 from "../assets/old-oven-pics/1.20_35020141_black.png"
import OldOven2_10 from "../assets/old-oven-pics/2.10_35020144_black.png"
import OldOven2_20 from "../assets/old-oven-pics/2.20_35020147_black.png"
import OldOven1_06 from "../assets/old-oven-pics/1.06_35020189_black.png"
import OldOven2_06 from "../assets/old-oven-pics/2.06_35020150_black.png"

export const mapNewOvenPic = (ovenInputs: OvenInputModel): string => {
  switch (ovenInputs.ovenType) {
    case OvenType.Hybrid:
      switch (ovenInputs.ovenSize) {
        case OvenSize.GN101:
          return Hybrid10_1
        case OvenSize.GN102:
          return Hybrid10_2
        case OvenSize.GN201:
          return Hybrid20_1
        case OvenSize.GN202:
          return Hybrid20_2
        case OvenSize.GN61:
          return Hybrid6_1
        case OvenSize.GN62:
          return Hybrid6_2

        default:
          return ""
      }
    case OvenType.Bake:
      switch (ovenInputs.ovenSize) {
        case OvenSize.BAKE6:
          return Bake6
        case OvenSize.BAKE9:
          return Bake9
        case OvenSize.BAKE16:
          return Bake16
        default:
          return ""
      }

    case OvenType.Combi:
      switch (ovenInputs.ovenSize) {
        case OvenSize.GN101:
          return Combi10_1
        case OvenSize.GN102:
          return Combi10_2
        case OvenSize.GN201:
          return Combi20_1
        case OvenSize.GN202:
          return Combi20_2
        case OvenSize.GN61:
          return Combi6_1
        case OvenSize.GN62:
          return Combi6_2

        default:
          return ""
      }

    default:
      return ""
  }
}

export const mapOldOvenPic = (ovenInputs: OvenInputModel): string => {
  switch (ovenInputs.ovenType) {
    case OvenType.Hybrid:
      switch (ovenInputs.ovenSize) {
        case OvenSize.GN101:
          return OldOven1_10
        case OvenSize.GN102:
          return OldOven1_20
        case OvenSize.GN201:
          return OldOven2_10
        case OvenSize.GN202:
          return OldOven2_20
        case OvenSize.GN61:
          return OldOven1_06
        case OvenSize.GN62:
          return OldOven2_06

        default:
          return ""
      }
    case OvenType.Bake:
      switch (ovenInputs.ovenSize) {
        case OvenSize.BAKE6:
          return OldOven1_06
        case OvenSize.BAKE9:
          return OldOven1_10
        case OvenSize.BAKE16:
          return OldOven1_20
        default:
          return ""
      }

    case OvenType.Combi:
      switch (ovenInputs.ovenSize) {
        case OvenSize.GN101:
          return OldOven1_10
        case OvenSize.GN102:
          return OldOven1_20
        case OvenSize.GN201:
          return OldOven2_10
        case OvenSize.GN202:
          return OldOven2_20
        case OvenSize.GN61:
          return OldOven1_06
        case OvenSize.GN62:
          return OldOven2_06

        default:
          return ""
      }

    default:
      return ""
  }
}
