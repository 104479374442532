import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { FlexRow } from "../../components/basic-components/flex-row"
import { PlusMinusInput } from "../../components/plus-minus-input"

export const SelectYourDay2Mobile: FC = React.memo(() => {
  const { hoursPerDay, daysPerWeek, weeksPerYear } = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("YourDay.Title2")}</Typography>
      <FlexRow gap="20px" flexWrap="wrap" justifyContent="center">
        <PlusMinusInput
          property={"hoursPerDay"}
          label={localized("OperationHoursPrDay")}
          defaultValue={hoursPerDay ?? undefined}
          maxValue={100}
          tabIndex={4}

        />
        <PlusMinusInput
          property={"daysPerWeek"}
          label={localized("OperationDaysPrWeek")}
          defaultValue={daysPerWeek ?? undefined}         
          maxValue={100}
          tabIndex={5}
        />
        <PlusMinusInput
          property={"weeksPerYear"}
          label={localized("OperationWeeksPrYear")}
          defaultValue={weeksPerYear ?? undefined}
          maxValue={100}
          tabIndex={6}
        />
      </FlexRow>
    </Box>
  )
})
