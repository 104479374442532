export function sumObject(
  obj: any,
  ignoreSingle?: string, // ignore single prop, throughout all objects
  objectFilter?: { key: string; value: string; include: boolean }, // Include or Exclude by key-value. Only determine on object layer
) {
  // Handle the 3 simple types, and null or undefined
  if (
    null == obj ||
    obj instanceof Date ||
    ("object" !== typeof obj && "number" !== typeof obj)
  ) {
    return 0
  }

  // Handle Array
  if (obj instanceof Array) {
    let acc = 0
    for (let i = 0, len = obj.length; i < len; i++) {
      acc += sumObject(obj[i], ignoreSingle, objectFilter)
    }
    return acc
  }

  // Handle Object
  if (obj instanceof Object) {
    let acc = 0
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr) && ignoreSingle !== attr) {
        if (
          (attr === objectFilter?.key &&
            obj[attr] === objectFilter.value &&
            objectFilter.include === false) ||
          (attr === objectFilter?.key &&
            obj[attr] !== objectFilter.value &&
            objectFilter.include === true)
        ) {
          return 0
        }
        acc += sumObject(obj[attr], ignoreSingle, objectFilter)
      }
    }

    return acc
  }

  // return if number, to be accumulated
  if ("number" === typeof obj) {
    return obj
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}
