import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { useSelector } from "react-redux"
import { StepperContentStyle } from "../../app/constants"
import { RootState } from "../../app/store"
import { FlexColumn } from "../../components/basic-components/flex-column"
import { FlexRow } from "../../components/basic-components/flex-row"
import { InputDropdown } from "../../components/input-dropdown"
import {
  localized,
  localizedDynamic,
  localizedInterpolation,
} from "../../i18n/i18n"
import {
  getCurrencyOptions,
  getWaterHardnessOptions,
} from "../../utils/enum-helper"

export const SelectPrices2: FC = React.memo(() => {
  const ovenInputs = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>
        {localizedInterpolation("Prices.Title2", {
          area: localizedDynamic("volume." + 0),
        })}
      </Typography>
      <FlexRow sx={{ gap: "50px" }}>
        <FlexColumn sx={{ alignItems: "center" }}>
          <Typography>
            {localizedInterpolation("waterPr", {
              area: localizedDynamic("volume." + 0),
            })}
          </Typography>
          <InputDropdown
            options={getCurrencyOptions()}
            defaultNumberValue={ovenInputs.waterPrice}
            defaultDropdownValue={ovenInputs.selectedCurrency}
            controlledDropdownValue={ovenInputs.selectedCurrency}
            inputProperty="waterPrice"
            dropdownProperty="selectedCurrency"
            maxValue={9999}
            tabIndex={14}
          />
        </FlexColumn>
        <FlexColumn sx={{ alignItems: "center" }}>
          <Typography>{localized("WaterHardness")}</Typography>
          <InputDropdown
            options={getWaterHardnessOptions()}
            defaultNumberValue={ovenInputs.waterHardness}
            defaultDropdownValue={ovenInputs.waterHardnessUnit}
            inputProperty="waterHardness"
            dropdownProperty="waterHardnessUnit"
            maxValue={9999}
            tabIndex={15}
 
          />
        </FlexColumn>
      </FlexRow>
    </Box>
  )
})
