import { Box, Typography } from "@mui/material"
import React, { FC, useState } from "react"
import { Slicer } from "../../components/slicer/slicer"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { PlusMinusInput } from "../../components/plus-minus-input"

export const SelectOvenAgeMobile: FC = React.memo(() => {
  const age = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs.ovenAge,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("ExistingOven.Title4")}</Typography>
      <Box sx={{ width: "100%", padding: "40px", maxWidth: "700px" }}>
        <PlusMinusInput
          property={"ovenAge"}
          label={""}
          defaultValue={age ?? undefined}
          maxValue={100}
          tabIndex={0}
        />
      </Box>
    </Box>
  )
})
