import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { PlusMinusInput } from "../../components/plus-minus-input"
import { FlexRow } from "../../components/basic-components/flex-row"
import { StepperContentStyle } from "../../app/constants"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
export const SelectYourDay3: FC = React.memo(() => {
  const existingCleaning = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("YourDay.Title3")}</Typography>
      <FlexRow gap="30px" flexWrap="wrap" justifyContent="center">
        <PlusMinusInput
          property={"turbo"}
          label={localized("Turbo")}
          defaultValue={existingCleaning.turbo}
          maxDigit={2}
          tabIndex={7}
        />
        <PlusMinusInput
          property={"light"}
          label={localized("Light")}
          defaultValue={existingCleaning.light}
          maxDigit={2}
          tabIndex={8}
        />
        <PlusMinusInput
          property={"medium"}
          label={localized("Medium")}
          defaultValue={existingCleaning.medium}
          maxDigit={2}
          tabIndex={9}
        />
        <PlusMinusInput
          property={"intense"}
          label={localized("Intense")}
          defaultValue={existingCleaning.intense}
          maxDigit={2}
          tabIndex={10}
        />
        <PlusMinusInput
          property={"eco"}
          label={localized("Eco")}
          defaultValue={existingCleaning.eco}
          maxDigit={2}
          tabIndex={11}
        />
        <PlusMinusInput
          property={"ecoPlus"}
          label={localized("EcoPlus")}
          defaultValue={existingCleaning.ecoPlus}
          maxDigit={2}
          tabIndex={12}
        />
      </FlexRow>
    </Box>
  )
})
