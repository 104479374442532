import { Box, Typography } from "@mui/material"
import React, { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { Colors, StepperContentStyle } from "../../app/constants"
import { RootState } from "../../app/store"
import { FlexColumn } from "../../components/basic-components/flex-column"
import { FlexRow } from "../../components/basic-components/flex-row"
import { CalculationDisplay } from "../../components/revenue-components/calculation-display"
import { RevenueDisplayOven } from "../../components/revenue-components/revenue-display-oven"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { useAppDispatch } from "../../app/hooks"
import { setOvenCostModels } from "../../state/oven-inputs/oven-input-slice"
import { CompareInvoqAndVC } from "../../utils/calculator/roi-calculator"

export const Revenue: FC = React.memo(() => {
  const dispatch = useAppDispatch()
  const {
    ovenInputs,
    yourDayCompleted,
    existingOvenCompleted,
    existingPricesCompleted,
  } = useSelector((state: RootState) => state.ovenInputs)

  useEffect(() => {
    if (existingOvenCompleted && existingPricesCompleted && yourDayCompleted) {
      dispatch(setOvenCostModels(CompareInvoqAndVC(ovenInputs)))
    }
  }, [
    dispatch,
    ovenInputs,
    existingOvenCompleted && existingPricesCompleted && yourDayCompleted,
  ])

  return (
    <>
      {existingOvenCompleted && existingPricesCompleted && yourDayCompleted ? (
        <FlexColumn sx={StepperContentStyle}>
          <FlexColumn
            sx={{
              ...StepperContentStyle,
              zIndex: 2,
              width: "100%",
              transform: "translate3d(0, 0, 0)", // This is neccessary for some iOS devices, to prevent flickers - see https://stackoverflow.com/a/58490946/6285142

            }}
          >
            <FlexRow sx={{ width: "100%", flex: 1, alignItems: "center" }}>
              <RevenueDisplayOven
                title={localized("existingOven")}
                ovenDetails={`Visual Cooking\n ${localizedDynamic(
                  ovenInputs.ovenType!,
                )} ${localizedDynamic(ovenInputs.powerSupply!)}\n ${
                  ovenInputs.ovenSize
                }`}
              />
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: Colors.white,
                  height: 60,
                  width: 60,
                  borderRadius: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  "@media (max-width:500px)": {
                    height: 30,
                    width: 30,
                  },
                }}
              >
                <Typography variant="h4"> VS</Typography>
              </Box>
              <RevenueDisplayOven
                title={localized("NewOven")}
                ovenDetails={`Invoq\n ${localizedDynamic(
                  ovenInputs.ovenType,
                )} ${localizedDynamic(ovenInputs.powerSupply!)}\n ${
                  ovenInputs.ovenSize
                }`}
                inverse={true}
              />
            </FlexRow>
            <CalculationDisplay />
          </FlexColumn>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "50%",
              flex: 1,
              backgroundColor: Colors.pageBackground2,
              zIndex: 1,
              transform: "translate3d(0, 0, 0)", // This is neccessary for some iOS devices, to prevent flickers - see https://stackoverflow.com/a/58490946/6285142
              height: "calc(100% - 30px)",
            }}
          />
        </FlexColumn>
      ) : (
        <Typography color={Colors.alert} style={{ margin: "auto " }}>
          {localized("NeedToFill")}
        </Typography>
      )}
    </>
  )
})
