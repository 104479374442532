import { DropdownOption } from "../components/basic-dropdown"

import { Unit } from "../enums/unit"
import { WaterHardness } from "../enums/water-hardness"
import { getLanguage, getLocalizedObject, localizedDynamic } from "../i18n/i18n"

export function getCurrencyOptions(): DropdownOption[] {
  return getLocalizedObject("currency").map((e: string, i: number) => ({
    label: e,
    value: i,
  }))
}

export function getUnitOptions(): DropdownOption[] {
  return Object.keys(Unit).map((e) => ({
    label: localizedDynamic(e),
    value: e,
  }))
}

// export function getWaterHardnessOptions(): DropdownOption[] {
//   return Object.values(WaterHardness).map((e) => ({
//     label: localizedDynamic(e),
//     value: e,
//   }))
// }

export function getWaterHardnessOptions(): DropdownOption[] {
  return getLocalizedObject("waterHardness").map((e: string, i: number) => ({
    label: e,
    value: i,
  }))
}
