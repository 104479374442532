import { Typography } from "@mui/material"
import React, { FC, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../app/hooks"
import { RootState } from "../../app/store"
import { TimeRange } from "../../enums/time-range"
import {
  localized,
  localizedDynamic,
  localizedInterpolation,
} from "../../i18n/i18n"
import { setSelectedTimerange } from "../../state/oven-inputs/oven-input-slice"
import { FlexColumn } from "../basic-components/flex-column"
import { BasicSwitch } from "../basic-switch"
import { DisplayCurrency } from "./display-currency"

type Props = { savings?: number }
export const RevenueSavings: FC<Props> = React.memo(({ savings }) => {
  const { ovenInputs } = useSelector((state: RootState) => state.ovenInputs)
  const { selectedTimerange } = useSelector(
    (state: RootState) => state.ovenInputs.calculateState,
  )

  const { selectedCurrency } = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs,
  )
  const dispatch = useAppDispatch()

  const options = useMemo(() => {
    return [
      { key: TimeRange.PerYear, value: localized("Year") },
      { key: TimeRange.PerWeek, value: localized("Week") },
      { key: TimeRange.PerDay, value: localized("Day") },
    ]
  }, [])

  const currencyFormatter = new Intl.NumberFormat(window.navigator.language, {
    maximumFractionDigits: 2,
  })

  const changeTimeRange = useCallback((key: TimeRange) => {
    dispatch(setSelectedTimerange(key))
  }, [])

  return (
    <FlexColumn
      sx={{ justifyContent: "center", alignItems: "center", gap: "5px" }}
    >
      <Typography fontSize={12}>
        {localizedInterpolation("SavingsIn", {
          area: localizedDynamic("currency." + selectedCurrency),
        })}
      </Typography>
      <FlexColumn sx={{ gap: 1 }}>
        <BasicSwitch
          options={options}
          onChange={changeTimeRange}
          selectedValue={selectedTimerange}
        />
      </FlexColumn>
      <DisplayCurrency variant="h3" value={savings} sx={{width: "4em"}} />
    </FlexColumn>
  )
})
