import { Box, Typography } from "@mui/material"
import React, { FC, useState } from "react"
import { Slicer } from "../../components/slicer/slicer"
import { localized, localizedDynamic } from "../../i18n/i18n"
import { StepperContentStyle } from "../../app/constants"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"

export const SelectOvenAge: FC = React.memo(() => {
  const age = useSelector(
    (state: RootState) => state.ovenInputs.ovenInputs.ovenAge,
  )
  return (
    <Box sx={StepperContentStyle}>
      <Typography>{localizedDynamic("ExistingOven.Title4")}</Typography>
      <Box sx={{ width: "100%", padding: "40px", maxWidth: '700px' }}>
        <Slicer
          defaultValue={age ?? undefined}
          postfix={localized("years")}
          marks={[
            { label: `1 ${localized('year')}`, value: 1 },
            { label: `25 ${localized('years')}`, value: 25 },
          ]}
          property="ovenAge"
        />
      </Box>
    </Box>
  )
})
